import React from 'react';
import {
  Firestore,
  collectionGroup,
  getDocs,
  QueryConstraint,
  where,
  limit,
  query,
  collection,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { Collection } from 'src/models/common';
import { AccountRepository } from 'src/repositories/account/interface';
import { getFieldName } from 'src/models/account';
import { toAccountFromDoc } from './converter';
import { LoginAccountState } from 'src/globalStates/loginAccount';

export const useAccountRepository = (): AccountRepository => {
  const db = useFirestoreClient();

  return React.useMemo(() => createAccountRepository(db), [db]);
};

const createAccountRepository = (db: Firestore) => {
  return {
    async getList(account: LoginAccountState) {
      if (!account.master) {
        return [];
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.Accounts
        }`
      );

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
      ];

      const snapshot = await getDocs(query(collRef, ...queries, limit(100)));

      return snapshot.docs.map((doc) => toAccountFromDoc(doc.data(), doc.ref));
    },

    async getDoc(email: string) {
      const collRef = collectionGroup(db, Collection.Accounts);

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        where(getFieldName('email'), '==', email),
      ];

      const snapshot = await getDocs(query(collRef, ...queries, limit(1)));

      if (snapshot.docs.length === 0) {
        throw new Error('エラーが発生しました');
      }

      return toAccountFromDoc(snapshot.docs[0].data()!, snapshot.docs[0].ref);
    },
  };
};
