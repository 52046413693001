import { message } from 'antd';
import React from 'react';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';
import { SurveyId } from 'src/models/survey';
import { MCustomerRepository } from 'src/repositories/mCustomer/interface';
import { useMCustomerRepository } from 'src/repositories/mCustomer/repository';
import { SurveyRepository } from 'src/repositories/survey/interface';
import { useSurveyRepository } from 'src/repositories/survey/repository';
import { toDownloadSurveyResultsFormat } from 'src/repositories/surveyResult/converter';
import { SurveyResultRepository } from 'src/repositories/surveyResult/interface';
import { useSurveyResultRepository } from 'src/repositories/surveyResult/repository';
import { download } from 'src/util/download';

export const useSurveyUsecase = () => {
  const resultRepo = useSurveyResultRepository();
  const surveyRepo = useSurveyRepository();
  const mCusRepo = useMCustomerRepository();
  const account = useLoginAccount();

  return React.useMemo(
    () => createSurveyUsecase({ surveyRepo, resultRepo, mCusRepo, account }),
    [surveyRepo, resultRepo, account]
  );
};

const createSurveyUsecase = ({
  surveyRepo,
  resultRepo,
  mCusRepo,
  account,
}: {
  surveyRepo: SurveyRepository;
  resultRepo: SurveyResultRepository;
  mCusRepo: MCustomerRepository;
  account: LoginAccountState;
}) => ({
  async downloadData(format: 'csv' | 'xlsx', surveyId: SurveyId) {
    try {
      const _results = await resultRepo.getListBySurveyId({
        surveyId,
      });

      const _survey = await surveyRepo.getDoc({ sId: surveyId, account });

      const _mCusResults = await mCusRepo.getAllDocs(account);

      const _value = _results.map((element, i) => {
        return {
          results: element, lineId: _mCusResults.find((v, i) => v.id === element.mCustomerId)?.lineId ?? undefined, lineName: _mCusResults.find((v, i) => v.id === element.mCustomerId)?.lineName ?? undefined
        }
      })

      download(
        toDownloadSurveyResultsFormat(_value, _survey),
        'アンケートデータ',
        format
      );
    } catch (error) {
      message.error('エラーが発生しました。更新して再度お試しください。');
    }
  },
});
