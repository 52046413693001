import React, { useState } from 'react';
import {
  Button,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { MCustomer } from 'src/models/mCustomer';
import { MCusPCard } from 'src/models/mCusPCard';
import { useMCusPcardItemByMCusId } from 'src/usecases/mCusPCard/reader';
import { ColumnsType } from 'antd/es/table';
import { useLoginAccount } from 'src/globalStates/loginAccount';
import { StoreOfAccount } from 'smartdishpro-scripts/ts_common';
import { EditOutlined } from '@ant-design/icons';
import { useMCusPCardUsecase } from 'src/usecases/mCusPCard/usecase';

type Props = {
  mCustomer: MCustomer;
};

const mCusPCardsLimit = 200;

const columns = (
  stores: StoreOfAccount[],
  onClickEditIcon: (value: MCusPCard) => void
): ColumnsType<MCusPCard> => {
  return [
    {
      title: 'ポイントカード',
      dataIndex: 'pointCardTitle',
      key: 'pointCardTitle',
      ellipsis: {
        showTitle: false,
      },
      render: (title: string) => {
        return (
          <Tooltip placement="topLeft" title={title}>
            {title}
          </Tooltip>
        );
      },
    },
    {
      title: 'ポイント',
      dataIndex: 'point',
      key: 'point',
      ellipsis: {
        showTitle: false,
      },
      render: (v: number, value: MCusPCard) => {
        return (
          <Flex
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography.Text>{`${v}pt`}</Typography.Text>
            <Tooltip title="ポイントの変更ができます">
              <Button
                type="dashed"
                icon={<EditOutlined />}
                onClick={() => onClickEditIcon(value)}
              />
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      title: 'カスタムネーム',
      dataIndex: 'name',
      key: 'name',
      ellipsis: {
        showTitle: false,
      },
      render: (name: string) => {
        return (
          <Tooltip placement="topLeft" title={name}>
            {name}
          </Tooltip>
        );
      },
    },
    {
      title: '番号',
      dataIndex: 'num',
      key: 'num',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'ポイントカード発行店舗',
      dataIndex: 'createdStoreId',
      key: 'createdStoreId',
      ellipsis: {
        showTitle: false,
      },
      render: (id: string) => {
        const _storeName =
          stores.find((s) => s.storeId === id)?.storeName ?? '';
        return (
          <Tooltip placement="topLeft" title={_storeName}>
            {_storeName}
          </Tooltip>
        );
      },
    },
  ];
};

export const MCusPCardTable: React.FC<Props> = ({ mCustomer }) => {
  const { stores } = useLoginAccount();

  const [messageApi, contextHolder] = message.useMessage();

  const { data, isLoading } = useMCusPcardItemByMCusId({
    mCustomerId: mCustomer.id!,
    limit: mCusPCardsLimit,
  });

  const { updateMCusPCard } = useMCusPCardUsecase();

  const [open, setOpen] = useState<{
    open: boolean;
    value?: MCusPCard;
  }>({ open: false });

  return (
    <div>
      {contextHolder}
      <Flex justify="space-between">
        <Typography.Title level={5}>ポイントカード</Typography.Title>
        <Typography.Text
          hidden={!((data?.cards.length ?? 0) >= mCusPCardsLimit)}
          style={{ fontSize: '10px' }}
        >
          {`※最大${mCusPCardsLimit}件`}
        </Typography.Text>
      </Flex>
      <Spin spinning={isLoading}>
        <Table<MCusPCard>
          size="small"
          columns={columns(stores, (value: MCusPCard) =>
            setOpen({ open: true, value })
          )}
          dataSource={data?.cards}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
          }}
          sticky
          scroll={{ x: 800, y: 1000 }}
        />
      </Spin>
      <CollectionCreateForm
        open={open.open}
        onCreate={async (p) => {
          if (p < 0 || (open.value?.point ?? 0) === p) {
            message.error('無効のポイントです');
            return;
          }

          if (open.value) {
            await updateMCusPCard({ ...open.value, point: p }, messageApi);
            setOpen({ open: false, value: undefined });
          }
        }}
        onCancel={() => {
          setOpen({ open: false });
        }}
        currentPoint={open.value?.point ?? 0}
      />
    </div>
  );
};

interface CollectionCreateFormProps {
  open: boolean;
  onCreate: (point: number) => Promise<void>;
  onCancel: () => void;
  currentPoint: number;
}

type FormValueType = { point: number | undefined };

const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
  open,
  onCreate,
  onCancel,
  currentPoint,
}) => {
  const [form] = Form.useForm<FormValueType>();

  const [loading, setLoading] = useState(false);

  const point = Form.useWatch('point', form);

  return (
    <Modal
      open={open}
      title="ポイントの変更"
      okText="変更"
      cancelText="キャンセル"
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={async () => {
        const value: number | undefined = await form.getFieldValue('point');
        if (!value) {
          return;
        }

        setLoading(true);
        await onCreate(Number(value) + Number(currentPoint));
        form.resetFields();
        setLoading(false);
      }}
    >
      <Form
        labelWrap
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14, offset: 1 }}
        form={form}
        layout="horizontal"
        name="form_in_modal"
        initialValues={{ modifier: 'public' }}
        style={{ width: '80%' }}
        disabled={loading}
      >
        <Form.Item name="" label="現在のポイント">
          <Typography.Text
            style={{ margin: '20px' }}
          >{`${currentPoint}pt`}</Typography.Text>
        </Form.Item>
        <Form.Item
          name="point"
          label="付与ポイント"
          required
          rules={[
            {
              required: true,
              message: 'ポイントを入力してください',
            },
          ]}
        >
          <Input type="number" min={-1000} max={1000} />
        </Form.Item>
        <Form.Item name="" label="付与後のポイント">
          <Typography.Text style={{ margin: '20px' }}>{`${
            Number(point ?? 0) + Number(currentPoint)
          }pt`}</Typography.Text>
        </Form.Item>
      </Form>
    </Modal>
  );
};
