type PathType = {
  [key: string]: {
    path: string;
    siderName: string;
    headerNames?: string[];
    groupName?: string;
  };
};

const RoutePaths: PathType = {
  top: { path: '/', siderName: '' },
  login: { path: '/login/', siderName: '' },
  list: { path: 'list/', siderName: 'リスト', headerNames: ['リスト'] },
  new: { path: 'new/', siderName: '作成', headerNames: ['作成'] },
  customer: {
    path: 'customer/',
    siderName: 'リスト',
    groupName: '顧客',
    headerNames: ['顧客'],
  },
  directMessage: {
    path: 'directMessage/',
    siderName: 'DM',
    groupName: 'LINE連携',
    headerNames: ['LINE連携', 'DM'],
  },
  broadCast: {
    path: 'broadCast/',
    siderName: '配信',
    groupName: 'LINE連携',
    headerNames: ['LINE連携', '配信'],
  },
  pointCard: {
    path: 'pointCard/',
    siderName: 'ポイントカード',
    groupName: 'LINE連携',
    headerNames: ['LINE連携', 'ポイントカード'],
  },
  survey: {
    path: 'survey/',
    siderName: 'アンケート',
    groupName: 'LINE連携',
    headerNames: ['LINE連携', 'アンケート'],
  },
  myaccount: {
    path: 'myaccount/',
    siderName: 'マイアカウント',
    groupName: 'アカウント',
    headerNames: ['アカウント管理', 'マイアカウント'],
  },
  account: {
    path: 'account/',
    siderName: 'リスト',
    groupName: 'アカウント',
    headerNames: ['アカウント'],
  },
};

export default RoutePaths;
