import { message } from 'antd';
import { Auth, onAuthStateChanged } from 'firebase/auth';
import React from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { useAuthClient } from 'src/firebase';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';
import { AccountRepository } from 'src/repositories/account/interface';
import { useAccountRepository } from 'src/repositories/account/repository';
import { AuthRepository } from 'src/repositories/auth/interface';
import { useAuthRepository } from 'src/repositories/auth/repository';
import RoutePaths from 'src/root/path';

export const useLoginUsecase = () => {
  const accountRepo = useAccountRepository();
  const authRepo = useAuthRepository();
  const loginAccount = useLoginAccount();
  const navigator = useNavigate();
  const auth = useAuthClient();

  return React.useMemo(
    () =>
      createLoginUsecase({
        accountRepo,
        authRepo,
        loginAccount,
        navigator,
        auth,
      }),
    [accountRepo, authRepo, loginAccount, navigator, auth]
  );
};

const createLoginUsecase = ({
  accountRepo,
  authRepo,
  loginAccount,
  navigator,
  auth,
}: {
  accountRepo: AccountRepository;
  authRepo: AuthRepository;
  loginAccount: LoginAccountState;
  navigator: NavigateFunction;
  auth: Auth;
}) => ({
  async login(email: string, password: string) {
    try {
      const authUser = await authRepo.signIn(email, password);
      const account = await accountRepo.getDoc(authUser.email!);
      const _hasClaim = await authRepo.getDashboardClaim(authUser);
      if (!_hasClaim) {
        message.error('権限がありません');
        return;
      }

      loginAccount.dispatch({
        type: 'SET_ITEM',
        payload: { ...account, auth: authUser },
      });

      navigator(RoutePaths.top.path, { replace: true });
    } catch (error) {
      message.error('エラーが発生しました');
    }
  },

  async logout() {
    try {
      await authRepo.signOut();

      loginAccount.dispatch({
        type: 'REMOVE_ITEM',
      });

      navigator(RoutePaths.login.path, { replace: true });
    } catch (_) {
      message.error('エラーが発生しました');
    }
  },

  checkCurrentUser() {
    return onAuthStateChanged(auth, async (_result) => {
      try {
        if (_result === null) {
          loginAccount.dispatch({
            type: 'REMOVE_ITEM',
          });
          navigator(RoutePaths.login.path, { replace: true });
          return;
        }

        const _hasClaim = await authRepo.getDashboardClaim(_result!);
        if (!_hasClaim) {
          return;
        }

        const account = await accountRepo.getDoc(_result!.email!);

        loginAccount.dispatch({
          type: 'SET_ITEM',
          payload: { ...account, auth: _result! },
        });
      } catch (error) {
        authRepo.signOut();
        message.error('エラーが発生しました');
      }
    });
  },
});
