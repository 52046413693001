import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TransHistory } from 'smartdishpro-scripts/ts_common';
import { dateToYMdhm } from 'src/util';
import { priceFormat } from 'src/util/number';

export const columns = (
  stores: { storeName: string; storeId: string }[]
): ColumnsType<TransHistory> => {
  return [
    {
      title: '来店日時',
      dataIndex: 'transCreatedAt',
      key: 'transCreatedAt',
      ellipsis: {
        showTitle: true,
      },
      render: (v: Date) => (
        <Tooltip placement="topLeft" title={dateToYMdhm(v)}>
          {dateToYMdhm(v)}
        </Tooltip>
      ),
    },
    {
      title: '来店店舗',
      dataIndex: 'storeId',
      key: 'storeId',
      ellipsis: {
        showTitle: true,
      },
      render: (v: string) => {
        const _value = stores
          .filter((store) => store.storeId === v)
          .map((store) => store.storeName)
          .join('\n');
        return (
          <Tooltip placement="topLeft" title={_value}>
            {_value}
          </Tooltip>
        );
      },
    },
    {
      title: '合計金額',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      ellipsis: {
        showTitle: false,
      },
      render: priceFormat,
    },
    {
      title: '来店人数',
      key: 'numOfCustomer',
      dataIndex: 'numOfCustomer',
      ellipsis: {
        showTitle: false,
      },
      render: (v: number) => `${v}名`,
    },
  ];
};
