import React from 'react';
import AppRoute from 'src/root';
import Theme from './Theme';

export default function App() {
  return (
    <React.StrictMode>
      <Theme>
        <AppRoute />
      </Theme>
    </React.StrictMode>
  );
}
