import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import RoutePaths from 'src/root/path';

export const Error: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="ページエラー"
      subTitle="お探しのページは見つかりません。一時的にアクセスできない状態か、移動もしくは削除された可能性があります。"
      extra={
        <Button type="primary" onClick={() => navigate(RoutePaths.top.path)}>
          トップページに戻る
        </Button>
      }
    />
  );
};
