import React, { useState } from 'react';
import { Button, Dropdown, MenuProps, Space, Tooltip } from 'antd';
import * as icon from '@ant-design/icons';

type Props = {
  name?: string;
  tooltip?: string;
  onClick: (format: 'csv' | 'xlsx') => Promise<void>;
  size?: 'small' | 'middle';
  type?: 'link' | 'primary';
};

export const DownloadButton: React.FC<Props> = ({
  name,
  tooltip,
  onClick,
  size = 'middle',
  type = 'primary',
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const items: MenuProps['items'] = [
    {
      label: '.csv',
      key: 'csv',
    },
    {
      label: '.xlsx',
      key: 'xlsx',
    },
  ];

  return (
    <Dropdown
      disabled={loading}
      menu={{
        items,
        selectable: true,
        onClick: async (e) => {
          setLoading(true);
          await onClick(e.key as 'csv' | 'xlsx');
          setLoading(false);
        },
      }}
    >
      <Button
        title="a"
        type={type}
        loading={loading}
        disabled={loading}
        size={size}
      >
        <Space>
          {loading ? 'ダウンロード中' : name ?? ''}
          <Tooltip title={tooltip}>
            <icon.DownOutlined />
          </Tooltip>
        </Space>
      </Button>
    </Dropdown>
  );
};
