import React from 'react';
import { Flex, Input } from 'antd';

type Props = {
  placeholder: string;
  onSearch: (value: string) => void;
  children?: React.ReactNode;
  isLoading?: boolean;
};

export const SearchBox: React.FC<Props> = ({
  placeholder,
  onSearch,
  children,
  isLoading,
}) => {
  return (
    <Flex
      style={{
        background: 'white',
        borderRadius: 3,
        padding: '2% 0 1% 2%',
        overflow: 'auto',
      }}
      vertical
      align="flex-start"
    >
      <h3 style={{ padding: '0%' }}>検索</h3>
      <div style={{ height: '10px' }}></div>
      <Flex style={{ width: '30%' }}>
        <Input.Search
          allowClear
          placeholder={placeholder}
          enterButton="検索"
          size="middle"
          loading={isLoading}
          style={{ paddingRight: '1%' }}
          onSearch={onSearch}
        />
      </Flex>
      <div style={{ padding: '1% 1% 0 0' }}>{children}</div>
    </Flex>
  );
};
