import { Survey, SurveyFormType, SurveyId } from './type';

export function getFieldName<K extends keyof Survey>(key: K): K {
  return key;
}

export function toSurveyId(id: string): SurveyId {
  return id as SurveyId;
}

export function toFormTypeFromSurvey(survey: Survey): SurveyFormType {
  return {
    survey: survey,
    ...survey,
    questions: survey.questions.map((q) => {
      const _v =
        q.selections?.map((_s) => {
          return {
            text: _s,
          };
        }) ?? [];

      return {
        ...q,
        selectionsForm: _v,
      };
    }),
    storeIds: survey.availableStoreIds,
  };
}
