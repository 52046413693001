import React from 'react';
import { Flex, Spin, Table, TableColumnsType, Typography } from 'antd';
import { MCustomer } from 'src/models/mCustomer';
import { SurveyResult } from 'src/models/surveyResult';
import { useSurveyResultItemByMCusId } from 'src/usecases/surveyResult/reader';
import { ColumnsType } from 'antd/es/table';
import { dateToYMdhm } from 'src/util';

type Props = {
  mCustomer: MCustomer;
};

const surveyResultsLimit = 200;

const columns: ColumnsType<SurveyResult> = [
  {
    title: '回答日時',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: {
      showTitle: false,
    },
    render: (v: Date) => dateToYMdhm(v),
    width: '20%',
  },
  {
    title: 'アンケート名',
    dataIndex: 'surveyTitle',
    key: 'surveyTitle',
    ellipsis: {
      showTitle: false,
    },
  },
];

const expandedRowRender = (result: SurveyResult) => {
  const columns: TableColumnsType<{}> = [
    {
      title: '質問',
      dataIndex: 'question',
      key: 'question',
      ellipsis: {
        showTitle: false,
      },
      width: '30%',
    },
    {
      title: '回答',
      dataIndex: 'answer',
      key: 'answer',
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  const data = [];
  for (let i = 0; i < result.answers.length; ++i) {
    data.push({
      key: i.toString(),
      question: result.answers[i].question ?? '',
      answer:
        result.answers[i].answerStr ?? result.answers[i].answerArray?.join(','),
    });
  }
  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export const SurveyResultTable: React.FC<Props> = ({ mCustomer }) => {
  const { data, isLoading } = useSurveyResultItemByMCusId({
    mCustomerId: mCustomer.id!,
    limit: surveyResultsLimit,
  });

  return (
    <div>
      <Flex justify="space-between">
        <Typography.Title level={5}>アンケート</Typography.Title>
        <Typography.Text
          hidden={!((data?.results.length ?? 0) >= surveyResultsLimit)}
          style={{ fontSize: '10px' }}
        >
          {`※最大${surveyResultsLimit}件`}
        </Typography.Text>
      </Flex>
      <Spin spinning={isLoading}>
        <Table<SurveyResult>
          size="small"
          columns={columns}
          expandable={{
            expandedRowRender,
          }}
          dataSource={data?.results}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
          }}
          sticky
          scroll={{ x: 800, y: 1000 }}
        />
      </Spin>
    </div>
  );
};
