import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { SearchBox } from 'src/components/ui';
import { MCustomerDetail } from 'src/components/model/mCustomer/detail';
import { DMList } from 'src/components/model/directMessage/list';
import { Chat } from 'src/components/model/directMessage/chat';
import {
  useMessageRoom,
  useMessageRoomList,
} from 'src/usecases/messageRoom/reader';
import { MessageRoom } from 'src/models/messageRoom';
import { toMCustomerId } from 'src/models/mCustomer';
import { useLocation, useNavigate } from 'react-router-dom';
import RoutePaths from 'src/root/path';
import { getRoomId } from 'src/root/path.function';
import { useMCustomerItem } from 'src/usecases/mCustomer/reader';

export const Message: React.FC = () => {
  const location = useLocation();

  const selectRoomId = getRoomId(location.pathname);

  const { data: room } = useMessageRoom({ mRoomId: selectRoomId });

  const [selectRoom, setSelectRoom] = useState<MessageRoom | null>(null);

  useEffect(() => {
    if (room?.room && !selectRoom) {
      setSelectRoom(room.room);
    }
    return () => {};
  }, [room, selectRoom]);

  const searchId = new URLSearchParams(location.search).get('id');

  const { data } = useMessageRoomList({ mCustomerId: searchId });

  const { data: selectRoomMCus } = useMCustomerItem({
    mCustomerId: selectRoom?.mCusId
      ? toMCustomerId(selectRoom!.mCusId)
      : undefined,
  });

  const navigate = useNavigate();

  const onClick = React.useCallback(
    (room: MessageRoom) => {
      setSelectRoom(room);
      if (!searchId) {
        navigate('/' + RoutePaths.directMessage.path + room.id, {
          replace: true,
        });
      }
    },
    [navigate, searchId]
  );

  const onSearch = React.useCallback(
    (_input: string) => {
      const _query = _input.replace(/\s/g, '');
      if (_query === '') {
        return;
      }

      setSelectRoom(null);
      navigate('/' + RoutePaths.directMessage.path + `?id=${_query}`, {
        replace: true,
      });
    },
    [navigate]
  );

  return (
    <div>
      <SearchBox placeholder="顧客IDを入力してください" onSearch={onSearch} />
      <div style={{ height: '10px' }}></div>
      <Flex justify="space-between" style={{ maxHeight: '75vh' }}>
        <DMList
          data={data?.rooms ?? []}
          selectingRoom={selectRoom}
          searchedRoom={searchId !== null}
          onSelect={onClick}
          onClear={() => {
            setSelectRoom(null);
            navigate('/' + RoutePaths.directMessage.path, {
              replace: true,
            });
          }}
        />
        <Chat
          selectRoom={{
            mCustomer: selectRoomMCus?.mCustomer ?? null,
            mRoom: selectRoom,
          }}
        />
        <Flex
          style={{
            width: '32%',
            background: 'white',
            borderRadius: 3,
            overflow: 'auto',
            padding: '2%',
          }}
        >
          <MCustomerDetail selectCus={selectRoomMCus?.mCustomer ?? null} />
        </Flex>
      </Flex>
    </div>
  );
};
