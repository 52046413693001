import useSWR from 'swr';
import { mCustomerCacheKeyGenerator } from './cache';
import { useMCustomerRepository } from 'src/repositories/mCustomer/repository';
import { MCustomer, MCustomerId } from 'src/models/mCustomer';
import { message } from 'antd';

export type MCustomerGetItemFormResponse = { mCustomer: MCustomer };

export type MCustomerGetItemQuery = {
  mCustomerId: MCustomerId | undefined;
};

export type MCustomerUpdateItemQuery = {
  mCustomer: MCustomer;
};

export type MCustomerGetItemResponse = {
  mCustomer: MCustomer | null;
};

export const useMCustomerItem = (query: MCustomerGetItemQuery) => {
  const repository = useMCustomerRepository();

  return useSWR<MCustomerGetItemResponse>(
    mCustomerCacheKeyGenerator.generateItemKey(query),
    async () => {
      if (!!!query.mCustomerId) {
        return { mCustomer: null };
      }

      return {
        mCustomer: await repository.getDoc(query),
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};
