import React from 'react';
import {
  getDocs,
  collection,
  Firestore,
  query,
  QueryConstraint,
  where,
  limit,
  orderBy,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { getFieldName } from 'src/models/messageRoom';
import { Collection } from 'src/models/common';
import {
  useLoginAccount,
  LoginAccountState,
} from 'src/globalStates/loginAccount';
import { MessageRoomRepository } from './interface';
import { toMessageRoomFromDoc } from './converter';
import {
  MessageRoomGetItemQuery,
  MessageRoomListQuery,
} from 'src/usecases/messageRoom/reader';
import { validData } from 'src/util/validate';
import { toUpdateFsDoc } from '../common/firestore';

export const useMessageRoomRepository = (): MessageRoomRepository => {
  const db = useFirestoreClient();
  const account = useLoginAccount();

  return React.useMemo(
    () => createMessageRoomRepository(db, account),
    [db, account]
  );
};

const createMessageRoomRepository = (
  db: Firestore,
  account: LoginAccountState
) => {
  return {
    async getList() {
      if (account.merchantId === undefined) {
        throw new Error('エラーが発生しました');
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.MessageRooms
        }`
      );

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        orderBy(getFieldName('lastMessageAt'), 'desc'),
      ];

      if (!account.master) {
        queries.push(where(getFieldName('storeId'), 'in', account.storeIds));
      }

      const snapshot = await getDocs(query(collRef, ...queries, limit(300)));

      return snapshot.docs.map((doc) =>
        toMessageRoomFromDoc(doc.data(), doc.id)
      );
    },

    async getDocFromMCusId({ mCustomerId }: MessageRoomListQuery) {
      if (account.merchantId === undefined || mCustomerId === undefined) {
        throw new Error('エラーが発生しました');
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MessageRooms
        }`
      );
      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        where(getFieldName('mCusId'), '==', mCustomerId),
      ];
      const snapshot = await getDocs(query(collRef, ...queries, limit(300)));

      return snapshot.docs
        .map((doc) => toMessageRoomFromDoc(doc.data(), doc.id))
        .filter((mRoom) => validData([mRoom.storeId], account));
    },

    async getDoc({ mRoomId }: MessageRoomGetItemQuery) {
      if (!!!account.merchantId || !!!mRoomId) {
        throw new Error('エラーが発生しました');
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MessageRooms
        }/${mRoomId.toString()}`
      );

      const snapshot = await getDoc(docRef);

      if (!snapshot.exists) {
        return null;
      }

      const _mRoom = toMessageRoomFromDoc(snapshot.data()!, snapshot.id);
      if (!account.storeIds?.includes(_mRoom.storeId)) {
        return null;
      }

      if (!validData([_mRoom.storeId], account)) {
        throw new Error('権限がありません');
      }

      return _mRoom;
    },

    async update({ mRoomId }: MessageRoomGetItemQuery) {
      if (!!!account.merchantId || !!!mRoomId) {
        throw new Error('エラーが発生しました');
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MessageRooms
        }/${mRoomId.toString()}`
      );

      await updateDoc(
        docRef,
        toUpdateFsDoc({
          unreadMessages: 0,
        })
      );
    },
  };
};
