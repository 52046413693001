import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Space,
  Typography,
  Avatar,
  Flex,
  Select,
} from 'antd';
import * as icon from '@ant-design/icons';
import { MCustomer, MCustomerId } from 'src/models/mCustomer';
import { dateToYMdhm } from 'src/util';
import { useMCustomerUsecase } from 'src/usecases/mCustomer/usecase';
import { useLoginAccount } from 'src/globalStates/loginAccount';
import { useMCustomerItem } from 'src/usecases/mCustomer/reader';

interface Prop {
  mCustomerId: MCustomerId;
}

export const MCustomerForm: React.FC<Prop> = ({ mCustomerId }) => {
  const { stores } = useLoginAccount();

  const { data } = useMCustomerItem({ mCustomerId });

  const { updateMCustomer, hikariFoodState } = useMCustomerUsecase();

  const [loading, isLoading] = useState(false);

  const [form] = Form.useForm<MCustomer>();

  const confirm = useCallback(
    async (value: MCustomer) => {
      isLoading(true);
      await updateMCustomer(value);
      isLoading(false);
    },
    [updateMCustomer]
  );

  useEffect(() => {
    form.resetFields();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.mCustomer?.id ?? mCustomerId]);

  if (!!!data?.mCustomer) return <></>;

  return (
    <div style={{ paddingTop: '3%' }}>
      <Flex vertical gap="middle">
        <Avatar
          src={data.mCustomer.linePictureUrl}
          style={{ alignSelf: 'center' }}
          size="large"
          icon={<icon.UserOutlined />}
        />
      </Flex>
      <div style={{ height: '20px' }}></div>
      <Form
        labelWrap
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14, offset: 1 }}
        layout="horizontal"
        style={{ margin: 'auto' }}
        form={form}
        onFinish={confirm}
        initialValues={data.mCustomer}
        disabled={loading}
      >
        <Form.Item<MCustomer> label="ID" name="id">
          <Typography.Paragraph
            className="ant-form-text"
            type="secondary"
            copyable
          >
            {data.mCustomer.id}
          </Typography.Paragraph>
        </Form.Item>
        <Form.Item<MCustomer> label="LINE ID" name="lineId">
          <Typography.Paragraph
            className="ant-form-text"
            type="secondary"
            copyable
          >
            {data.mCustomer.lineId}
          </Typography.Paragraph>
        </Form.Item>
        <Form.Item<MCustomer> label="名前" name="lineName">
          <Typography.Text className="ant-form-text" type="secondary">
            {data.mCustomer.lineName}
          </Typography.Text>
        </Form.Item>
        <Form.Item<MCustomer> label="店舗" name="storeIds">
          <Typography.Text className="ant-form-text" type="secondary">
            {data.mCustomer.storeIds
              .map((id) => stores.find((s) => s.storeId === id)?.storeName)
              .join(',')}
          </Typography.Text>
        </Form.Item>
        <Form.Item<MCustomer> label="登録日時" name="createdAt">
          <Typography.Text className="ant-form-text" type="secondary">
            {dateToYMdhm(data.mCustomer.createdAt)}
          </Typography.Text>
        </Form.Item>
        <Form.Item<MCustomer>
          label="ニックネーム"
          name="nickname"
          rules={[{ min: 1, max: 20 }]}
          tooltip="こちらはお客さまに公開されません"
        >
          <Input />
        </Form.Item>
        <Form.Item<MCustomer>
          label={hikariFoodState().firstAttribute}
          name="firstAttribute"
          rules={[{ max: 200 }]}
          tooltip="自動反映される属性です。スタッフへの変更は可能となります。"
          hidden={!hikariFoodState().isAccount}
        >
          <Select
            placeholder="お選びください"
            options={[
              {
                value: '3軍',
                label: '3軍（自動反映）',
                disabled: data.mCustomer.firstAttribute !== '3軍',
              },
              {
                value: '2軍',
                label: '2軍（自動反映）',
                disabled: data.mCustomer.firstAttribute !== '2軍',
              },
              {
                value: '1軍',
                label: '1軍（自動反映）',
                disabled: data.mCustomer.firstAttribute !== '1軍',
              },
              {
                value: 'null',
                label: '自動反映に切替 ※翌日に反映されます',
                disabled: data.mCustomer.firstAttribute !== 'スタッフ',
              },
              { value: 'スタッフ', label: 'スタッフ' },
            ]}
          />
        </Form.Item>
        <Form.Item<MCustomer>
          label={hikariFoodState().secondAttribute}
          name="secondAttribute"
          rules={[{ max: 200 }]}
          tooltip="自動反映される属性です。スタッフへの変更は可能となります。"
          hidden={!hikariFoodState().isAccount}
        >
          <Select
            placeholder="お選びください"
            options={[
              {
                value: '3軍',
                label: '3軍（自動反映）',
                disabled: data.mCustomer.secondAttribute !== '3軍',
              },
              {
                value: '2軍',
                label: '2軍（自動反映）',
                disabled: data.mCustomer.secondAttribute !== '2軍',
              },
              {
                value: '1軍',
                label: '1軍（自動反映）',
                disabled: data.mCustomer.secondAttribute !== '1軍',
              },
              {
                value: 'null',
                label: '自動反映に切替 ※翌日に反映されます',
                disabled: data.mCustomer.secondAttribute !== 'スタッフ',
              },
              { value: 'スタッフ', label: 'スタッフ' },
            ]}
          />
        </Form.Item>
        <Form.Item<MCustomer>
          label="コメント"
          name="remarks"
          rules={[{ max: 200 }]}
          tooltip="こちらはお客さまに公開されません"
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 10 }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              保存
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};
