import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RoutePaths from 'src/root/path';
import { useLoginUsecase } from 'src/usecases/account/usecase';

type Props = {
  children: React.ReactNode;
};

export const Authentication: React.FC<Props> = ({ children }) => {
  const _auth = useLoginUsecase();
  const location = useLocation();
  const navigator = useNavigate();

  React.useEffect(() => {
    const _subscribe = _auth.checkCurrentUser();

    if (location.pathname === '/') {
      navigator(RoutePaths.customer.path + RoutePaths.list.path, {
        replace: true,
      });
    }
    return () => _subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};
