import { ColumnsType } from 'antd/es/table';
import { Account, getFieldName } from 'src/models/account';

export const columns: ColumnsType<Account> = [
  {
    title: 'メールアドレス',
    dataIndex: getFieldName('email'),
    key: getFieldName('email'),
  },
  {
    title: '店舗',
    dataIndex: getFieldName('stores'),
    key: getFieldName('stores'),
    render(_, record, __) {
      return record.stores.map((_s) => _s.storeName).join(', ');
    },
  },
];
