import React from 'react';
import {
  getDocs,
  collection,
  Firestore,
  query,
  QueryConstraint,
  where,
  limit,
  orderBy,
  addDoc,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { getFieldName, Message } from 'src/models/message';
import { Collection } from 'src/models/common';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';
import { toMessageFromDoc } from './converter';
import { MessageGetListQuery, MessageRepository } from './interface';
import { toCreateFsDoc } from '../common/firestore';

export const useMessageRepository = (): MessageRepository => {
  const db = useFirestoreClient();
  const account = useLoginAccount();

  return React.useMemo(
    () => createMessageRepository(db, account),
    [db, account]
  );
};

export type MessageCreateQuery = {
  content: string;
  contentType: 'text' | 'image';
  destinationUserId: string;
  messageRoomId: string;
};

const createMessageRepository = (db: Firestore, account: LoginAccountState) => {
  return {
    async getList(listQuery: MessageGetListQuery) {
      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.MessageRooms
        }/${listQuery.mRoomId}/${Collection.Messages}`
      );

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
      ];

      if (listQuery.lastCreatedAt) {
        queries.push(
          where(getFieldName('createdAt'), '<', listQuery.lastCreatedAt)
        );
      }

      queries.push(orderBy(getFieldName('createdAt'), 'desc'));

      const snapshot = await getDocs(
        query(collRef, ...queries, limit(listQuery.queryLimit))
      );

      return snapshot.docs.map((doc) => toMessageFromDoc(doc.data(), doc.id));
    },

    async createDoc(query: MessageCreateQuery) {
      if (account.merchantId === undefined) {
        throw new Error('エラーが発生しました');
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.MessageRooms
        }/${query.messageRoomId}/${Collection.Messages}`
      );

      const _doc: Message = {
        content: query.content,
        contentType: query.contentType,
        createdAt: new Date(),
        deletedAt: null,
        documentStatus: 'active',
        error: false,
        messageType: 'storeToUser',
        updatedAt: new Date(),
      };

      await addDoc(collRef, toCreateFsDoc(_doc));
    },
  };
};
