import React from 'react';
import { Account } from 'src/models/account';
import { LoginAccountStateAction } from './reducer';

export const useLoginAccount = () => React.useContext(LoginAccountContext);

export type LoginAccountState = Account & {
  dispatch: (action: LoginAccountStateAction) => void;
};

export const LoginAccountContext = React.createContext<LoginAccountState>({
  createdAt: new Date(),
  deletedAt: null,
  documentStatus: 'active',
  email: '',
  master: false,
  stores: [],
  updatedAt: new Date(),
  dispatch: () => {},
});
