import React from 'react';
import { AppHeader } from 'src/components/ui';
import { SurveyCreateForm } from './survey.create';

export const SurveyCreatePage: React.FC = () => {
  return (
    <AppHeader>
      <SurveyCreateForm />
    </AppHeader>
  );
};
