import React, { useState } from 'react';
import { Flex } from 'antd';
import { MCustomerTable } from 'src/components/model/mCustomer/table';
import { DownloadButton, SearchBox } from 'src/components/ui';
import { useMCustomerItem } from 'src/usecases/mCustomer/reader';
import { toMCustomerId } from 'src/models/mCustomer';
import { useMCustomerUsecase } from 'src/usecases/mCustomer/usecase';

export const MCustomer: React.FC = () => {
  const [input, setInput] = useState<string | undefined>();
  const { data, isLoading } = useMCustomerItem({
    mCustomerId: input ? toMCustomerId(input) : undefined,
  });
  const _state = useMCustomerUsecase();

  return (
    <div>
      <Flex
        style={{
          background: 'white',
          borderRadius: 3,
          padding: '2%',
        }}
        vertical
        align="flex-start"
      >
        <h3 style={{ padding: '0%' }}>データダウンロード</h3>
        <div style={{ height: '10px' }}></div>
        <DownloadButton
          name="全顧客データダウンロード"
          onClick={_state.downloadAllMCustomers}
        />
      </Flex>
      <div style={{ height: '10px' }}></div>
      <SearchBox
        placeholder="顧客IDを入力してください"
        onSearch={setInput}
        children={
          data?.mCustomer ? (
            <MCustomerTable mCustomer={data.mCustomer} />
          ) : (
            <></>
          )
        }
        isLoading={isLoading}
      />
    </div>
  );
};
