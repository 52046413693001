import React from 'react';
import { useSWRConfig } from 'swr';
import { MCustomerGetItemQuery } from './reader';

// Cache Key Generator
export const mCustomerCacheKeyGenerator = {
  generateItemKey: ({ mCustomerId }: MCustomerGetItemQuery) => {
    return ['MCUSTOMER', 'ITEM', mCustomerId] as const;
  },
};

// Cache Mutator
export const useMCustomerCacheMutator = () => {
  const { mutate } = useSWRConfig();

  return React.useMemo(
    () => ({
      mutateItem: (query: MCustomerGetItemQuery) =>
        mutate(mCustomerCacheKeyGenerator.generateItemKey(query)),
    }),
    [mutate]
  );
};
