import React, { useCallback } from 'react';
import {
  Form,
  Input,
  Button,
  Card,
  Space,
  DatePicker,
  Upload,
  Select,
  Image,
  UploadProps,
  message,
  Flex,
  Typography,
  Radio,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { BroadCastFormType } from 'src/models/broadCast';
import { useLoginAccount } from 'src/globalStates/loginAccount';
import TextArea from 'antd/es/input/TextArea';
import { csvToArray } from 'src/util/array';
import { useBroadCastForm } from 'src/usecases/broadCast/form';
import { download } from 'src/util/download';
import { DownloadButton } from 'src/components/ui';
import { RcFile } from 'antd/es/upload';
import dayjs from 'dayjs';

interface Prop {
  init?: BroadCastFormType;
}

export const BroadCastForm: React.FC<Prop> = ({ init }) => {
  const loginAccount = useLoginAccount();

  const _formState = useBroadCastForm();

  const [form] = Form.useForm<BroadCastFormType>();

  const type = Form.useWatch('type', form);

  const lineIds = Form.useWatch('sendMCustomerLineIds', form);

  const [inputImage, setInputImage] = React.useState<RcFile | undefined>();

  const _needText =
    type === 'text' || type === 'textAndImage' || type === 'textAndTemplate';
  const _needImage = type === 'image' || type === 'textAndImage';
  const _needTemplate = type === 'template' || type === 'textAndTemplate';

  const reader = new FileReader();

  const props: UploadProps = {
    accept: 'text/csv',
    action: '/upload.do',
    maxCount: 1,
    showUploadList: lineIds !== null,
    beforeUpload: (file) => {
      const csvFile = file.type === 'text/csv';
      if (!csvFile) {
        message.error(`csvファイルのみアップロード可能です`);
        return Upload.LIST_IGNORE;
      }

      try {
        reader.readAsText(file, 'UTF-8');
        reader.onload = (e) => {
          if (!!!e?.target?.result || typeof e?.target?.result !== 'string') {
            message.error(`フォーマットが無効です`);
            return false;
          }

          const _csv = csvToArray(e.target.result.toString());
          if (_csv.length < 2 || !_csv[0].includes('LINE_ID')) {
            message.error(`フォーマットが無効です`);
            return false;
          }

          const findIndex = _csv[0].indexOf('LINE_ID');
          const _lineIds = _csv
            .filter(
              (_v, _i) =>
                _i !== 0 && _v[findIndex] !== '' && _v[findIndex] !== undefined
            )
            .map((_v) => _v[findIndex])
            .flat();

          if (_lineIds.length > 500) {
            message.error(`送信するユーザを指定する場合は最大500名です`);
            return false;
          }

          form.setFieldValue('sendMCustomerLineIds', _lineIds);

          return false;
        };
        return false;
      } catch (error) {
        message.error(`エラーが発生しました`);
        return Upload.LIST_IGNORE;
      }
    },
    onRemove: () =>
      form.setFieldValue(
        'sendMCustomerLineIds',
        init?.broadCast?.sendMCustomerLineIds
      ),
  };

  const imageProps: UploadProps = {
    accept: 'image/*',
    action: '/upload.do',
    maxCount: 1,
    showUploadList: inputImage !== undefined,
    beforeUpload: (file) => {
      const isImage =
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg';
      if (!isImage) {
        message.error(`png,jpeg,jpg形式のみアップロード可能です`);
        return Upload.LIST_IGNORE;
      }

      setInputImage(file);

      return false;
    },
    onRemove: () => setInputImage(undefined),
  };

  const [loading, setLoading] = React.useState(false);
  const onSubmit = async (status: 'draft' | 'reserved') => {
    setLoading(true);
    const _value = form.getFieldsValue();
    await _formState.submit(
      {
        ..._value,
        broadCast: init?.broadCast,
      },
      status,
      inputImage
    );
    setLoading(false);
  };

  const onClickSendUserIds = useCallback(
    async (format: 'csv' | 'xlsx') => {
      if (!init?.broadCast) return;
      const _title = init.broadCast.title;
      download(
        [
          ['LINE_ID'],
          ...(init.broadCast.sendMCustomerLineIds ?? []).map((_id) => [_id]),
        ],
        `${_title ?? ''}${_title ? '_' : ''}ユーザリスト`,
        format
      );
    },
    [init?.broadCast]
  );

  const onClickSampleData = useCallback(async (format: 'csv' | 'xlsx') => {
    download(
      [
        ['LINE_ID'],
        ['U716f5f7517de3aadglfg2c7d83055910'],
        ['Uff64d02382d4asd7c825c5e7c30c20e0'],
        ['U50d2c782b8fbsdsffg6966caf4aca8e5'],
      ],
      'サンプルデータ',
      format
    );
  }, []);

  return (
    <Card style={{ paddingTop: '3%' }}>
      <Form
        labelWrap
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16, offset: 1 }}
        layout="horizontal"
        style={{ maxWidth: 900, margin: 'auto' }}
        form={form}
        initialValues={init}
        disabled={loading}
      >
        <Form.Item<BroadCastFormType>
          label="タイトル"
          name="title"
          rules={[{ required: true, message: 'タイトルを入力してください' }]}
          tooltip="こちらはお客さまに公開されません"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="storeIds"
          label="送信するアカウント"
          rules={[
            {
              message: '送信する公式アカウントを選択してください',
              type: 'array',
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="送信する公式アカウントを選択してください"
          >
            {loginAccount.stores?.map((_store) => {
              return (
                <Select.Option value={_store.storeId} key={_store.storeId}>
                  {_store.storeName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="sendAt"
          label="送信日時"
          getValueProps={(i) => ({
            value: !!!i ? undefined : dayjs(i),
          })}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            disabledDate={(d) => d.diff(dayjs(), 'seconds') < 0}
          />
        </Form.Item>
        <Form.Item
          name="type"
          label="送信内容タイプ"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              { value: 'text', label: 'テキストのみ' },
              { value: 'image', label: '画像のみ' },
              {
                value: 'template',
                label: 'アクション付き画像のみ（クーポンなど）',
              },
              { value: 'textAndImage', label: 'テキスト＆画像' },
              {
                value: 'textAndTemplate',
                label: 'テキスト＆アクション付き画像',
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          hidden={!_needText}
          name="text"
          label="テキスト"
          required={_needText}
        >
          <TextArea minLength={1} maxLength={300} />
        </Form.Item>
        <Form.Item
          name="image"
          hidden={!(_needImage || _needTemplate)}
          label="画像"
          required={_needImage || _needTemplate}
        >
          <Space style={{ maxWidth: '100%', overflow: 'auto' }}>
            <Upload.Dragger {...imageProps} style={{ maxWidth: '400px' }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                クリックまたはファイルをドラッグ
              </p>
              <p className="ant-upload-hint">
                png,jpeg,jpg形式に対応しています
              </p>
            </Upload.Dragger>
            <Image
              src={
                _needImage
                  ? init?.broadCast?.message.imageUrl ?? undefined
                  : init?.broadCast?.message.tempImageUrl ?? undefined
              }
              width="200px"
            />
          </Space>
        </Form.Item>
        <Form.Item
          hidden={!_needTemplate}
          name="tempLabel"
          label="画像上のテキスト"
          tooltip={<Image width={200} src="/assets/images/tempLabel.jpg" />}
          required={_needTemplate}
        >
          <Input
            type="text"
            placeholder="クーポンをみる"
            minLength={1}
            maxLength={12}
          />
        </Form.Item>
        <Form.Item
          hidden={!_needTemplate}
          name="altText"
          label="通知に表示されるテキスト"
          required={_needTemplate}
        >
          <Input type="text" maxLength={400} />
        </Form.Item>
        <Form.Item
          hidden={!_needTemplate}
          name="tempUri"
          label="リンク先"
          required={_needTemplate}
        >
          <Input type="url" placeholder="http://mysite.com" maxLength={1000} />
        </Form.Item>
        <Form.Item
          name="sendAll"
          label="送信対象"
          required
          tooltip="送信者を限定する場合のみアップロードが必要です。アップロードがない場合は友達全員に送信されます。"
        >
          <Radio.Group>
            <Radio value={true}>全員</Radio>
            <Radio value={false}>限定</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="sendMCustomerLineIds"
          label={
            <Flex vertical gap={10} align="end">
              <Typography.Text style={{ marginRight: '10px' }}>
                限定ユーザ
              </Typography.Text>
              <DownloadButton
                name="サンプルデータ"
                onClick={onClickSampleData}
                size="small"
                type="link"
              />
            </Flex>
          }
        >
          <Flex
            gap={10}
            style={{ maxWidth: '100%', overflow: 'auto', alignItems: 'end' }}
          >
            <Upload.Dragger {...props} style={{ maxWidth: '400px' }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                クリックまたはファイルをドラッグ
              </p>
              <p
                className="ant-upload-hint"
                style={{ whiteSpace: 'pre-wrap', textAlign: 'start' }}
              >{`※送信したい顧客のLINEのIDをアップロードする必要があります\n※データの１行目に「LINE_ID」が含まれている必要があります\n※最大500ユーザとなります\n※csv形式に対応しています`}</p>
            </Upload.Dragger>
            <div
              hidden={
                (init?.broadCast?.sendMCustomerLineIds?.length ?? 0) === 0
              }
            >
              <DownloadButton
                tooltip="アップロードしたユーザリストをダウンロードできます"
                onClick={onClickSendUserIds}
              />
            </div>
          </Flex>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Space>
            <Button
              disabled={init?.broadCast?.status === 'sent'}
              loading={loading}
              type="dashed"
              htmlType="submit"
              onClick={() => onSubmit('draft')}
            >
              下書き保存
            </Button>
            <Button
              disabled={init?.broadCast?.status === 'sent'}
              loading={loading}
              type="primary"
              htmlType="button"
              onClick={() => onSubmit('reserved')}
            >
              確定
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};
