import useSWR from 'swr';
import { MCustomerId } from 'src/models/mCustomer';
import { SurveyResult } from 'src/models/surveyResult';
import { useSurveyResultRepository } from 'src/repositories/surveyResult/repository';
import { surveyResultCacheKeyGenerator } from './cache';
import { SurveyId } from 'src/models/survey';
import { message } from 'antd';

export type SurveyResultGetListFormResponse = {
  results: SurveyResult[];
};

export type SurveyResultGetListBySurveyIdQuery = {
  surveyId: SurveyId;
};

export type SurveyResultGetListByMCusIdQuery = {
  mCustomerId: MCustomerId;
  limit: number;
};

export const useSurveyResultItemByMCusId = (
  query: SurveyResultGetListByMCusIdQuery
) => {
  const repository = useSurveyResultRepository();

  return useSWR<SurveyResultGetListFormResponse>(
    surveyResultCacheKeyGenerator.generateItemKey(query),
    async () => {
      return {
        results: await repository.getListByMCusId(query),
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};
