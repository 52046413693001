import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import MCustomerPage from 'src/components/page/mCustomer/list';
import ErrorPage from 'src/components/page/error';
import DirectMessagePage from 'src/components/page/directMessage';
import TopPage from 'src/components/page/top';
import RoutePaths from 'src/root/path';
import BroadCastListPage from 'src/components/page/broadCast/list';
import PointCardListPage from 'src/components/page/pointCard/list';
import LoginPage from 'src/components/page/login';
import BroadCastCreatePage from 'src/components/page/broadCast/create';
import BroadCastEditPage from 'src/components/page/broadCast/edit';
import MyAccountPage from 'src/components/page/account/myaccount';
import PointCardEditPage from 'src/components/page/pointCard/edit';
import PointCardCreatePage from 'src/components/page/pointCard/create';
import SurveyListPage from 'src/components/page/survey/list';
import SurveyCreatePage from 'src/components/page/survey/create';
import SurveyEditPage from 'src/components/page/survey/edit';
import AccountListPage from 'src/components/page/account/list';

export const router = createBrowserRouter([
  {
    path: RoutePaths.top.path,
    element: <TopPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: (
          <Navigate
            to={RoutePaths.customer.path + RoutePaths.list.path}
            replace
          />
        ),
      },
      {
        path: RoutePaths.customer.path,
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <MCustomerPage />,
          },
          {
            path: RoutePaths.list.path,
            element: <MCustomerPage />,
          },
        ],
      },
      {
        path: RoutePaths.directMessage.path,
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <DirectMessagePage />,
          },
          {
            path: ':id',
            element: <DirectMessagePage />,
          },
        ],
      },
      {
        path: RoutePaths.broadCast.path,
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <BroadCastListPage />,
          },
          {
            path: ':id',
            element: <BroadCastEditPage />,
          },
          {
            path: 'new',
            element: <BroadCastCreatePage />,
          },
        ],
      },
      {
        path: RoutePaths.pointCard.path,
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <PointCardListPage />,
          },
          {
            path: ':id',
            element: <PointCardEditPage />,
          },
          {
            path: 'new',
            element: <PointCardCreatePage />,
          },
        ],
      },
      {
        path: RoutePaths.survey.path,
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <SurveyListPage />,
          },
          {
            path: ':id',
            element: <SurveyEditPage />,
          },
          {
            path: 'new',
            element: <SurveyCreatePage />,
          },
        ],
      },
      {
        path: RoutePaths.myaccount.path,
        element: <MyAccountPage />,
      },
      {
        path: RoutePaths.account.path + RoutePaths.list.path,
        element: <AccountListPage />,
      },
    ],
  },
  {
    path: RoutePaths.login.path,
    element: <LoginPage />,
  },
]);
