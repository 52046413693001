import React from 'react';
import {
  getDocs,
  collection,
  Firestore,
  query,
  QueryConstraint,
  where,
  limit,
  orderBy,
  startAfter,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { Collection } from 'src/models/common';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';
import { SurveyResultRepository } from './interface';
import { getFieldName, SurveyResult } from 'src/models/surveyResult';
import { toSurveyResultFromDoc } from './converter';
import {
  SurveyResultGetListBySurveyIdQuery,
  SurveyResultGetListByMCusIdQuery,
} from 'src/usecases/surveyResult/reader';

export const useSurveyResultRepository = (): SurveyResultRepository => {
  const db = useFirestoreClient();
  const account = useLoginAccount();

  return React.useMemo(
    () => createSurveyResultRepository(db, account),
    [db, account]
  );
};

const createSurveyResultRepository = (
  db: Firestore,
  account: LoginAccountState
) => {
  async function _getList(queries: QueryConstraint[], limitNum: number) {
    const collRef = collection(
      db,
      `${Collection.Merchants}/${account.merchantId!.toString()}/${
        Collection.SurveyResults
      }`
    );
    const snapshot = await getDocs(query(collRef, ...queries, limit(limitNum)));

    return snapshot.docs.map((doc) =>
      toSurveyResultFromDoc(doc.data(), doc.id)
    );
  }

  return {
    async getListByMCusId({
      mCustomerId,
      limit,
    }: SurveyResultGetListByMCusIdQuery) {
      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        where(getFieldName('mCustomerId'), '==', mCustomerId.toString()),
        orderBy(getFieldName('createdAt'), 'desc'),
      ];

      return _getList(queries, limit);
    },

    async getListBySurveyId({ surveyId }: SurveyResultGetListBySurveyIdQuery) {
      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        where(getFieldName('surveyId'), '==', surveyId.toString()),
        orderBy(getFieldName('createdAt'), 'desc'),
      ];

      const data: SurveyResult[] = [];

      for (let index = 0; index < 10000; index++) {
        queries.push(
          startAfter(data[data.length - 1]?.createdAt ?? new Date())
        );
        const _snap = await _getList(queries, 200);

        data.push(..._snap);
        if (_snap.length < 200) {
          break;
        }
      }
      return data;
    },
  };
};
