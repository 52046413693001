import useSWR from 'swr';
import {
  BroadCast,
  BroadCastFormType,
  BroadCastId,
  toFormTypeFromBroadCast,
} from 'src/models/broadCast';
import { useBroadCastRepository } from 'src/repositories/braodCast/repository';
import { broadCastCacheKeyGenerator } from './cache';
import { message } from 'antd';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';

export type BroadCastGetListQuery = { account: LoginAccountState };

type BroadCastGetListResponse = { broadCasts: BroadCast[] };

export const useBroadCastList = () => {
  const repository = useBroadCastRepository();
  const account = useLoginAccount();

  return useSWR<BroadCastGetListResponse>(
    broadCastCacheKeyGenerator.generateListKey({
      query: { account },
      ignore: account.merchantId === undefined,
    }),
    async () => {
      const _getValue = await repository.getList({ account });
      return {
        broadCasts: _getValue,
      };
    },
    {
      onError: (_) => {
        message.error(
          '配信リストの取得時にエラーが発生しました。更新して再度お試しください。'
        );
      },
    }
  );
};

export type BroadCastGetItemQuery = {
  broadCastId: BroadCastId;
  account: LoginAccountState;
};

export type BroadCastGetItemResponse = { broadCast: BroadCastFormType };

export const useBroadCastFormItem = ({ bId }: { bId: BroadCastId }) => {
  const repository = useBroadCastRepository();
  const account = useLoginAccount();

  return useSWR<BroadCastGetItemResponse>(
    broadCastCacheKeyGenerator.generateItemKey({
      query: { bId },
      ignore: account.merchantId === undefined,
    }),
    async () => {
      const _getValue = await repository.getDoc({ broadCastId: bId, account });
      return {
        broadCast: toFormTypeFromBroadCast(_getValue),
      };
    },
    {
      onError: (_) => {
        message.error(
          '配信データの取得時にエラーが発生しました。更新して再度お試しください。'
        );
      },
    }
  );
};
