import React from 'react';
import {
  getDocs,
  collection,
  Firestore,
  query,
  QueryConstraint,
  where,
  limit,
  addDoc,
  getDoc,
  doc,
  updateDoc,
  orderBy,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { Collection } from 'src/models/common';
import { LoginAccountState } from 'src/globalStates/loginAccount';
import { PointCardRepository } from './interface';
import { getFieldName, PointCard, toPointCardId } from 'src/models/pointCard';
import { toPointCardFromDoc } from './converter';
import { validData } from 'src/util/validate';
import { toCreateFsDoc, toUpdateFsDoc } from '../common/firestore';
import {
  PointCardGetItemQuery,
  PointCardGetListQuery,
} from 'src/usecases/pointCard/reader';

export const usePointCardRepository = (): PointCardRepository => {
  const db = useFirestoreClient();

  return React.useMemo(() => createPointCardRepository(db), [db]);
};

const createPointCardRepository = (db: Firestore) => {
  return {
    async getList({ account }: PointCardGetListQuery) {
      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.PointCards
        }`
      );

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        orderBy(getFieldName('createdAt'), 'desc'),
      ];

      const snapshot = await getDocs(query(collRef, ...queries, limit(200)));

      return snapshot.docs
        .map((doc) => toPointCardFromDoc(doc.data(), doc.id))
        .filter((_pCard) => validData(_pCard.availableStoreIds, account));
    },

    async getDoc({ pId, account }: PointCardGetItemQuery) {
      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.PointCards
        }/${pId.toString()}`
      );

      const snapshot = await getDoc(docRef);
      if (snapshot.data() === undefined) {
        throw new Error('エラーが発生しました');
      }

      const _value = toPointCardFromDoc(snapshot.data()!, snapshot.id);

      const _validate = validData(_value.availableStoreIds, account);
      if (!_validate) {
        throw new Error('権限がありません');
      }

      return _value;
    },

    async createDoc(pointCard: PointCard, account: LoginAccountState) {
      if (!validData(pointCard.availableStoreIds, account)) {
        throw new Error(
          'アクセス可能の店舗に権限のある店舗が含まれている必要があります'
        );
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.PointCards
        }`
      );

      const _result = await addDoc(collRef, toCreateFsDoc(pointCard));

      return this.getDoc({ pId: toPointCardId(_result.id), account });
    },

    async updateDoc(pointCard: PointCard, account: LoginAccountState) {
      const _pointId = pointCard.id;

      if (!!!_pointId) {
        throw new Error('エラーが発生しました。再度お試しください。');
      }

      if (!validData(pointCard.availableStoreIds, account)) {
        throw new Error(
          'アクセス可能の店舗に権限のある店舗が含まれている必要があります'
        );
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.PointCards
        }/${_pointId}`
      );

      await updateDoc(docRef, toUpdateFsDoc(pointCard));
      return this.getDoc({ pId: _pointId, account });
    },
  };
};
