import { RouterProvider } from 'react-router-dom';
import { LoginAccountProvider } from 'src/globalStates/loginAccount';
import { router } from 'src/root/router';

export const AppRoute: React.FC = () => {
  return (
    <LoginAccountProvider>
      <RouterProvider router={router} />
    </LoginAccountProvider>
  );
};
