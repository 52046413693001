import React from 'react';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';
import { MCustomer } from 'src/models/mCustomer';
import { MCustomerRepository } from 'src/repositories/mCustomer/interface';
import { useMCustomerRepository } from 'src/repositories/mCustomer/repository';
import { toDownloadDataFormat } from 'src/repositories/mCustomer/converter';
import {
  convertFirstAttribute,
  convertSecondAttribute,
  isHikariFood,
} from 'src/components/function/customMerchant';
import { download } from 'src/util/download';
import { message } from 'antd';
import { useMCustomerCacheMutator } from './cache';

export const useMCustomerUsecase = () => {
  const repository = useMCustomerRepository();
  const account = useLoginAccount();
  const mutator = useMCustomerCacheMutator();

  return React.useMemo(
    () => createMCustomerUsecase({ repository, account, mutator }),
    [repository, account, mutator]
  );
};

export interface MCustomerUsecase {
  updateMCustomer(value: MCustomer): Promise<MCustomer>;
  downloadAllMCustomers(format: 'csv' | 'xlsx'): Promise<void>;
  account?: LoginAccountState;
}

const createMCustomerUsecase = ({
  repository,
  account,
  mutator,
}: {
  repository: MCustomerRepository;
  account: LoginAccountState;
  mutator: ReturnType<typeof useMCustomerCacheMutator>;
}) => ({
  hikariFoodState() {
    return {
      isAccount: isHikariFood(account.merchantId!),
      firstAttribute: convertFirstAttribute(account.merchantId!),
      secondAttribute: convertSecondAttribute(account.merchantId!),
    };
  },

  async updateMCustomer(value: MCustomer) {
    try {
      const _newCustomers = {
        ...value,
        firstAttribute:
          value.firstAttribute === 'null' ? null : value.firstAttribute,
        secondAttribute:
          value.secondAttribute === 'null' ? null : value.secondAttribute,
      };

      await repository.updateDoc({
        mCustomer: _newCustomers,
      });

      message.success('保存しました');

      mutator.mutateItem({ mCustomerId: value.id });
    } catch (error) {
      message.error('予期せぬエラーが発生しました。再度お試しください。');
    }
  },

  async downloadAllMCustomers(format: 'csv' | 'xlsx') {
    try {
      const _results = await repository.getAllDocs(account);
      const _data = toDownloadDataFormat(_results, account);
      download(_data, '顧客データ', format);
    } catch (error) {
      message.error(
        '予期せぬエラーが発生しました。ページを更新して再度お試しください。'
      );
    }
  },
});
