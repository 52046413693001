import React from 'react';
import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Survey, getFieldName } from 'src/models/survey';
import { useNavigate } from 'react-router-dom';
import { useSurveyList } from 'src/usecases/survey/reader';
import { DownloadButton } from 'src/components/ui';
import { useSurveyUsecase } from 'src/usecases/survey/usecase';

export const SurveyTable: React.FC = () => {
  const navigate = useNavigate();

  const onClickEdit = React.useCallback(
    (v: Survey) => {
      return {
        onClick: () => {
          if (!v?.id) return;
          navigate(v.id!);
          return;
        },
      };
    },
    [navigate]
  );

  const values = useSurveyList();

  const _state = useSurveyUsecase();

  const columns: ColumnsType<Survey> = [
    {
      title: 'タイトル',
      dataIndex: getFieldName('title'),
      key: getFieldName('title'),
    },
    {
      title: '質問項目',
      dataIndex: getFieldName('questions'),
      key: getFieldName('questions'),
      ellipsis: {
        showTitle: true,
      },
      render(_, record, __) {
        return (
          <Typography.Text style={{ maxLines: 2 }}>
            {record.questions.map((_q) => _q.question).join(', ')}
          </Typography.Text>
        );
      },
    },
    {
      title: 'ダウンロードデータ',
      dataIndex: 'export',
      key: 'export',
      render(_, record, __) {
        return (
          <a onClick={(event) => event.stopPropagation()} href={_}>
            <DownloadButton
              name="アンケートデータ"
              onClick={(f) => _state.downloadData(f, record.id!)}
            />
          </a>
        );
      },
    },
  ];

  return (
    <Table<Survey>
      columns={columns}
      dataSource={values.data?.surveys ?? []}
      onRow={onClickEdit}
    />
  );
};
