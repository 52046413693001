import useSWR from 'swr';
import { useMCusPCardRepository } from 'src/repositories/mCusPCard/repository';
import { mCusPCardCacheKeyGenerator } from './cache';
import { MCusPCard } from 'src/models/mCusPCard';
import { MCustomerId } from 'src/models/mCustomer';
import { PointCardId } from 'src/models/pointCard';
import { message } from 'antd';

export type MCusPCardGetListFormResponse = {
  cards: MCusPCard[];
};

export type MCusPcardGetListByPCardIdQuery = {
  pCardId: PointCardId;
};

export type MCusPcardGetListByMCusIdQuery = {
  mCustomerId: MCustomerId;
  limit: number;
};

export const useMCusPcardItemByMCusId = (
  query: MCusPcardGetListByMCusIdQuery
) => {
  const repository = useMCusPCardRepository();

  return useSWR<MCusPCardGetListFormResponse>(
    mCusPCardCacheKeyGenerator.generateListKey({ query }),
    async () => {
      return {
        cards: await repository.getListByMCusId(query),
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};
