import React, { useCallback } from 'react';
import {
  Form,
  Input,
  Button,
  Card,
  Select,
  message,
  DatePicker,
  Typography,
  Flex,
  Image,
  Space,
} from 'antd';
import { PointCardFormType } from 'src/models/pointCard';
import { usePointCardForm } from 'src/usecases/pointCard/form';
import { useLoginAccount } from 'src/globalStates/loginAccount';
import { useStoreList } from 'src/usecases/store/reader';
import { InboxOutlined } from '@ant-design/icons';
import Upload, { RcFile, UploadProps } from 'antd/es/upload';
import dayjs from 'dayjs';

interface Prop {
  init?: PointCardFormType;
}

export const PointCardForm: React.FC<Prop> = ({ init }) => {
  const loginAccount = useLoginAccount();

  const _formState = usePointCardForm();

  const [form] = Form.useForm<PointCardFormType>();

  const [inputImage, setInputImage] = React.useState<RcFile | undefined>();

  const { data } = useStoreList({
    storeIds: init?.availableStoreIds,
  });

  const imageProps: UploadProps = {
    accept: 'image/*',
    action: '/upload.do',
    maxCount: 1,
    showUploadList: inputImage !== undefined,
    beforeUpload: (file) => {
      const isImage =
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg';
      if (!isImage) {
        message.error(`png,jpeg,jpg形式のみアップロード可能です`);
        return Upload.LIST_IGNORE;
      }

      setInputImage(file);

      return false;
    },
    onRemove: () => setInputImage(undefined),
  };

  const [loading, setLoading] = React.useState(false);

  const _submit = useCallback(
    async (v: PointCardFormType) => {
      setLoading(true);
      await _formState.submit({ ...v, pointCard: init?.pointCard }, inputImage);
      setLoading(false);
    },
    [_formState, init?.pointCard, inputImage]
  );

  return (
    <Card style={{ paddingTop: '3%' }}>
      <Form
        labelWrap
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14, offset: 1 }}
        layout="horizontal"
        style={{ maxWidth: 900, margin: 'auto' }}
        form={form}
        onFinish={_submit}
        initialValues={init}
        disabled={loading}
      >
        <Form.Item<PointCardFormType>
          label="ポイントカード名"
          name="title"
          rules={[
            { required: true, message: 'ポイントカード名を入力してください' },
          ]}
          style={{ padding: '1%' }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="availableStoreIds"
          label="利用可能店舗"
          rules={[
            {
              required: true,
              message: '利用可能の店舗を選択してください',
              type: 'array',
            },
          ]}
          style={{ padding: '1%' }}
        >
          <Select
            mode="multiple"
            placeholder="利用可能の店舗を選択してください"
          >
            {loginAccount.stores?.map((_store) => {
              return (
                <Select.Option value={_store.storeId} key={_store.storeId}>
                  {_store.storeName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="expireAt"
          label="有効期限"
          tooltip="ポイントカードの発行、ポイントの付与ができる期間"
          style={{ padding: '1%' }}
          getValueProps={(i) => ({
            value: !!!i ? undefined : dayjs(i),
          })}
          {...{
            rules: [
              {
                message: '日時を選択してください',
                required: true,
              },
            ],
          }}
        >
          <DatePicker
            format="YYYY-MM-DD"
            disabledDate={(d) => d.diff(dayjs(), 'days') < 1}
          />
        </Form.Item>
        <Form.Item name="image" label="画像" style={{ padding: '1%' }}>
          <Space>
            <Upload.Dragger {...imageProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                クリックまたはファイルをドラッグ
              </p>
              <p className="ant-upload-hint">png,jpeg,jpgに対応しています</p>
            </Upload.Dragger>
            <Image src={init?.pointCard?.imageUrl ?? undefined} width="200px" />
          </Space>
        </Form.Item>
        <Form.Item name="caution" label="注意書き" style={{ padding: '1%' }}>
          <Input.TextArea />
        </Form.Item>
        {init ? (
          <Form.Item label="URL" style={{ padding: '1%' }}>
            {init.availableStoreIds.map((_id) => {
              const _findStore = data?.stores.find(
                (_store) => _store.id === _id
              );

              if (!!!_findStore) {
                return <div key={_id}></div>;
              }
              const _url =
                _findStore.liffUrl + '/point_cards/' + init!.pointCard?.id ??
                '';
              return (
                <Flex
                  key={_id}
                  gap={5}
                  vertical
                  style={{ paddingBottom: '20px' }}
                >
                  <Typography.Text style={{ paddingRight: '10px' }}>
                    {_findStore.storeName}
                  </Typography.Text>
                  <Typography.Paragraph copyable>{_url}</Typography.Paragraph>
                </Flex>
              );
            })}
          </Form.Item>
        ) : (
          <></>
        )}
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            確定
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
