import React from 'react';
import { useSWRConfig } from 'swr';
import { MessageRoomGetItemQuery, MessageRoomListQuery } from './reader';

// Cache Key Generator
export const messageRoomCacheKeyGenerator = {
  generateItemKey: ({
    query,
    ignore = false,
  }: {
    query?: MessageRoomGetItemQuery;
    ignore?: boolean;
  }) => {
    if (ignore) {
      return;
    }

    return ['MESSAGEROOM', 'ITEM', query] as const;
  },
  generateListKey: ({
    query,
    ignore = false,
  }: {
    query?: MessageRoomListQuery;
    ignore?: boolean;
  }) => {
    if (ignore) {
      return;
    }
    return ['MESSAGEROOM', 'LIST', ...[query]] as const;
  },
};

// Cache Mutator
export const useMessageRoomCacheMutator = () => {
  const { mutate } = useSWRConfig();

  return React.useMemo(
    () => ({
      mutateList: (query?: MessageRoomListQuery) =>
        mutate(messageRoomCacheKeyGenerator.generateListKey({ query })),
    }),
    [mutate]
  );
};
