export const AppColors = {
  white: '#ffffff',
  lightBlue: '#11b4fa',
  black: '#000000',
  darkGray: '#606060',
  lightGray: '#f2f2f2',
  lightPink: '#ee534f',
  red: '#f35444',
  orange: '#f7993f',
  green: '#57b11f',
  blue: '#2a80b9',
  pink: '#f77bac',
  yellow: '#fbdd2a',
  lightGreen: '#a7e037',
  purple: '#8d44ad',
  brown: '#cc9f5f',
};
