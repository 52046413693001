import React from 'react';
import { SurveyForm } from 'src/components/model/survey/form';
import { SurveyFormType } from 'src/models/survey';

type Props = {
  formValue: SurveyFormType;
};

export const SurveyEditForm: React.FC<Props> = ({ formValue }) => {
  return <SurveyForm init={formValue} />;
};
