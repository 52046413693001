import { toBroadCastId } from 'src/models/broadCast';
import { toMCusPCardId } from 'src/models/mCusPCard';
import { toMCustomerId } from 'src/models/mCustomer';
import { toMessageRoomId } from 'src/models/messageRoom';
import { toPointCardId } from 'src/models/pointCard';
import { toSurveyId } from 'src/models/survey';
import RoutePaths from 'src/root/path';

export function pathToHeaderCrubms(path: string): { title: string }[] {
  return path
    .split('/')
    .map((_path) =>
      (
        Object.values(RoutePaths).find((route) => route.path === `${_path}/`)
          ?.headerNames ?? []
      ).map((_v) => {
        return { title: _v };
      })
    )
    .flat();
}

export function getBroadCastId(path: string) {
  return toBroadCastId(path.split('/')[2]);
}

export function getMCustomerId(path: string) {
  return toMCustomerId(path.split('/')[3]);
}

export function getPointCardId(path: string) {
  return toPointCardId(path.split('/')[2]);
}

export function getSurveyId(path: string) {
  return toSurveyId(path.split('/')[2]);
}

export function getRoomId(path: string) {
  const _paths = path.split('/');
  return _paths.length >= 2 ? toMessageRoomId(path.split('/')[2]) : null;
}

export function getMCusPCardId(path: string) {
  return toMCusPCardId(path.split('/')[2]);
}
