import useSWR from 'swr';
import { accountCacheKeyGenerator } from './cache';
import { Account } from 'src/models/account';
import { useAccountRepository } from 'src/repositories/account/repository';
import { message } from 'antd';
import { useLoginAccount } from 'src/globalStates/loginAccount';

type AccountGetListResponse = { accounts: Account[] };

export const useAccountList = () => {
  const repository = useAccountRepository();
  const account = useLoginAccount();

  return useSWR<AccountGetListResponse>(
    accountCacheKeyGenerator.generateListKey({ query: { account } }),
    async () => {
      const _getValue = await repository.getList(account);
      return {
        accounts: _getValue,
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};
