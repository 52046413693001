import React from 'react';
import { Flex, Spin, Table, Typography } from 'antd';
import { MCustomer } from 'src/models/mCustomer';
import { useTransHistoryItem } from 'src/usecases/transHistory/reader';
import { columns } from './table.type';
import { useLoginAccount } from 'src/globalStates/loginAccount';
import { TransHistory } from 'smartdishpro-scripts/ts_common';

type Props = {
  mCustomer: MCustomer;
};

const transHistoriesLimit = 200;

export const TransHistoryTable: React.FC<Props> = ({ mCustomer }) => {
  const { stores } = useLoginAccount();
  const { data, isLoading } = useTransHistoryItem({
    mCustomerId: mCustomer.id!,
    limit: transHistoriesLimit,
  });

  return (
    <div>
      <Flex justify="space-between">
        <Typography.Title level={5}>来店履歴</Typography.Title>
        <Typography.Text
          hidden={!((data?.transHistories.length ?? 0) >= transHistoriesLimit)}
          style={{ fontSize: '10px' }}
        >
          {`※最大${transHistoriesLimit}件`}
        </Typography.Text>
      </Flex>
      <Spin spinning={isLoading}>
        <Table<TransHistory>
          size="small"
          columns={columns(stores)}
          dataSource={data?.transHistories}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
          }}
          sticky
          scroll={{ x: 500, y: 1000 }}
        />
      </Spin>
    </div>
  );
};
