import useSWR from 'swr';
import { surveyCacheKeyGenerator } from './cache';
import {
  Survey,
  SurveyFormType,
  SurveyId,
  toFormTypeFromSurvey,
} from 'src/models/survey';
import { useSurveyRepository } from 'src/repositories/survey/repository';
import { message } from 'antd';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';

export type SurveyGetListQuery = { account: LoginAccountState };

type SurveyGetListResponse = { surveys: Survey[] };

export const useSurveyList = () => {
  const repository = useSurveyRepository();
  const account = useLoginAccount();

  return useSWR<SurveyGetListResponse>(
    surveyCacheKeyGenerator.generateListKey({
      query: { account },
      ignore: !account.merchantId,
    }),
    async () => {
      return {
        surveys: await repository.getList({ account }),
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};

export type SurveyGetItemQuery = { sId: SurveyId; account: LoginAccountState };

export type SurveyGetItemResponse = { survey: SurveyFormType };

export const useSurveyFormItem = ({ sId }: { sId: SurveyId }) => {
  const repository = useSurveyRepository();
  const account = useLoginAccount();

  return useSWR<SurveyGetItemResponse>(
    surveyCacheKeyGenerator.generateItemKey({
      query: { sId, account },
      ignore: !account.merchantId,
    }),
    async () => {
      return {
        survey: toFormTypeFromSurvey(
          await repository.getDoc({ sId: sId, account })
        ),
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};
