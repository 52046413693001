import { DocumentData } from 'firebase/firestore/lite';
import { SurveyResultFromJson } from 'smartdishpro-scripts/ts_common';
import { Survey } from 'src/models/survey';
import { SurveyResult, toSurveyResultId } from 'src/models/surveyResult';
import { dateToYMdhm } from 'src/util';
import { escapeSpecialChar } from 'src/util/string';

export const toSurveyResultFromDoc = (
  data: DocumentData,
  id: string
): SurveyResult => {
  const _result = SurveyResultFromJson(data);

  return {
    ..._result,
    id: toSurveyResultId(id),
    key: id,
  };
};

export const toDownloadSurveyResultsFormat = (
  surveys: { results: SurveyResult, lineId: string | undefined, lineName: string | undefined }[],
  survey: Survey
): string[][] => {
  const _questions = survey.questions.map((_v) => ({
    question: _v.question,
    id: _v.id,
  }));

  for (const survey of surveys) {
    for (const answer of survey.results.answers) {
      if (!_questions.find((_v) => _v.id === answer.id)) {
        _questions.push({ id: answer.id, question: answer.question });
      }
    }
  }

  const _data = surveys.map((v) => {
    const _v = v.results;
    return [
      _v.id ?? '',
      _v.mCustomerId ?? '',
      v.lineId ?? '',
      v.lineName ?? '',
      _v.surveyId ?? '',
      _v.answeredStoreId ?? '',
      dateToYMdhm(_v.createdAt),
      ..._questions.map((_que) => {
        const _value = _v.answers.find((_a) => _a.id === _que.id);
        if (_value?.type === 'checkbox') {
          return escapeSpecialChar(_value?.answerArray?.join('、') ?? '');
        } else {
          return escapeSpecialChar(_value?.answerStr ?? '');
        }
      }),
    ];
  });

  const _title = [
    '回答ID',
    '顧客ID',
    'LINEID',
    'LINE名前',
    'アンケートID',
    '回答店舗ID',
    '回答日時',
    ..._questions.map((_v) => escapeSpecialChar(_v.question)),
  ];

  return [_title, ..._data];
};
