export const dateToYMd = (date: Date): string => {
  const symbol = {
    Y: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
  };

  const formatted = 'yyyy-MM-dd'.replace(/(Y+|M+|d+)/g, (v) =>
    (
      (v.length > 1 ? '0' : '') + symbol[v.slice(-1) as keyof typeof symbol]
    ).slice(-2)
  );

  return formatted.replace(/(y+)/g, (v) =>
    date.getFullYear().toString().slice(-v.length)
  );
};

export const dateToYMdhm = (date: Date): string => {
  const symbol = {
    Y: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
  };

  const formatted = 'yyyy-MM-dd hh:mm'.replace(/(Y+|M+|d+|h+|m+)/g, (v) =>
    (
      (v.length > 1 ? '0' : '') + symbol[v.slice(-1) as keyof typeof symbol]
    ).slice(-2)
  );

  return formatted.replace(/(y+)/g, (v) =>
    date.getFullYear().toString().slice(-v.length)
  );
};

export const dateToMdhmWithLine = (date: Date): string => {
  const symbol = {
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
  };

  const formatted = 'MM/dd\nhh:mm'.replace(/(M+|d+|h+|m+)/g, (v) =>
    (
      (v.length > 1 ? '0' : '') + symbol[v.slice(-1) as keyof typeof symbol]
    ).slice(-2)
  );

  return formatted.replace(/(y+)/g, (v) =>
    date.getFullYear().toString().slice(-v.length)
  );
};

export const dateToYMdhmS = (date: Date): string => {
  const symbol = {
    Y: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    S: date.getSeconds(),
  };

  const formatted = 'yyyy_MM_dd_hh_mm_S'.replace(/(Y+|M+|d+|h+|m+|S+)/g, (v) =>
    (
      (v.length > 1 ? '0' : '') + symbol[v.slice(-1) as keyof typeof symbol]
    ).slice(-2)
  );

  return formatted.replace(/(y+)/g, (v) =>
    date.getFullYear().toString().slice(-v.length)
  );
};

export const dateTohm = (date: Date): string => {
  const symbol = {
    h: date.getHours(),
    m: date.getMinutes(),
  };

  const formatted = 'hh:mm'.replace(/(h+|m+)/g, (v) =>
    (
      (v.length > 1 ? '0' : '') + symbol[v.slice(-1) as keyof typeof symbol]
    ).slice(-2)
  );

  return formatted.replace(/(y+)/g, (v) =>
    date.getFullYear().toString().slice(-v.length)
  );
};
