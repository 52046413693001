import { DocumentData } from 'firebase/firestore/lite';
import { MessageFromJson } from 'smartdishpro-scripts/ts_common';
import { Message, toMessageId } from 'src/models/message';

export const toMessageFromDoc = (data: DocumentData, id: string): Message => {
  const _room = MessageFromJson(data);
  return {
    ..._room,
    id: toMessageId(id),
    key: id,
  };
};
