import React from 'react';
import { LoginAccountContext } from 'src/globalStates/loginAccount/context';
import { loginAccountReducer } from './reducer';

type Props = {
  children: React.ReactNode;
};

export const LoginAccountProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = React.useReducer(loginAccountReducer, {
    createdAt: new Date(),
    deletedAt: null,
    documentStatus: 'active',
    email: '',
    master: false,
    stores: [],
    updatedAt: new Date(),
  });

  return (
    <LoginAccountContext.Provider value={{ ...state, dispatch }}>
      {children}
    </LoginAccountContext.Provider>
  );
};
