import React from 'react';
import {
  Firestore,
  getDocs,
  query,
  collection,
  QueryConstraint,
  where,
  limit,
  orderBy,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { Collection } from 'src/models/common';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';
import { TransHistoryRepository } from './interface';
import { toTransHistoryFromDoc } from './converter';
import { getFieldName } from 'src/models/transHistory';
import { TransHistoryGetListQuery } from 'src/usecases/transHistory/reader';

export const useTransHistoryRepository = (): TransHistoryRepository => {
  const db = useFirestoreClient();
  const account = useLoginAccount();
  return React.useMemo(
    () => createTransHistoryRepository(db, account),
    [db, account]
  );
};

const createTransHistoryRepository = (
  db: Firestore,
  account: LoginAccountState
) => {
  return {
    async getAllDocs(appQuery: TransHistoryGetListQuery) {
      if (account.merchantId === undefined || account.storeIds === undefined) {
        throw new Error('エラーが発生しました');
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId?.toString()}/${
          Collection.TransHistories
        }`
      );

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        where(getFieldName('mCustomerId'), '==', appQuery.mCustomerId),
        orderBy(getFieldName('transCreatedAt'), 'desc'),
      ];

      if (!account.master) {
        queries.push(where(getFieldName('storeId'), 'in', account.storeIds));
      }

      return (
        await getDocs(query(collRef, ...queries, limit(appQuery.limit)))
      ).docs.map((_doc) => toTransHistoryFromDoc(_doc.data()!, _doc.id));
    },
  };
};
