import { message } from 'antd';
import React from 'react';
import { MessageRoomRepository } from 'src/repositories/messageRoom/interface';
import { useMessageRoomRepository } from 'src/repositories/messageRoom/repository';
import { useMessageRoomCacheMutator } from './cache';
import { MessageRoomGetItemQuery } from './reader';

export const useMessageRoomUsecase = () => {
  const repository = useMessageRoomRepository();
  const mutator = useMessageRoomCacheMutator();

  return React.useMemo(
    () =>
      createMessageRoomUsecase({
        repository,
        mutator,
      }),
    [repository, mutator]
  );
};

const createMessageRoomUsecase = ({
  repository,
  mutator,
}: {
  repository: MessageRoomRepository;
  mutator: ReturnType<typeof useMessageRoomCacheMutator>;
}) => ({
  async updateRoomToRead(query: MessageRoomGetItemQuery) {
    try {
      await repository.update(query);
      mutator.mutateList({ mCustomerId: null });
    } catch (error) {
      message.error('エラーが発生しました。更新してもう一度お試しください。');
    }
  },
});
