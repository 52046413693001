import React from 'react';
import { BroadCastId } from 'src/models/broadCast';
import { useSWRConfig } from 'swr';
import { BroadCastGetItemResponse, BroadCastGetListQuery } from './reader';

// Cache Key Generator
export const broadCastCacheKeyGenerator = {
  generateListKey: ({
    query,
    ignore = false,
  }: {
    query?: BroadCastGetListQuery;
    ignore?: boolean;
  }) => {
    if (ignore) {
      return;
    }
    return ['BROADCAST', 'LIST', ...[query]] as const;
  },
  generateItemKey: ({
    query,
    ignore = false,
  }: {
    query: { bId: BroadCastId | undefined };
    ignore?: boolean;
  }) => {
    if (ignore) {
      return;
    }
    return ['BROADCAST', 'ITEM', query.bId] as const;
  },
};

// Cache Mutator
export const useBroadCastCacheMutator = () => {
  const { mutate } = useSWRConfig();

  return React.useMemo(
    () => ({
      mutateList: (query: BroadCastGetListQuery) =>
        mutate(broadCastCacheKeyGenerator.generateListKey({ query: query })),
      mutateItem: (
        query: { bId: BroadCastId | undefined },
        newData?: BroadCastGetItemResponse,
        revalidate?: boolean
      ) =>
        mutate(
          broadCastCacheKeyGenerator.generateItemKey({ query }),
          newData,
          revalidate
        ),
    }),
    [mutate]
  );
};
