export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function csvToArray(csv: string) {
  const rows = csv.split('\r\n' || '\n');
  const result = [];

  for (const row of rows) {
    const values = row.split(',');
    result.push(values);
  }

  return result;
}
