import React from 'react';
import { Auth, signInWithEmailAndPassword, User } from 'firebase/auth';
import { useAuthClient } from 'src/firebase';
import { AuthRepository } from './interface';

export const useAuthRepository = (): AuthRepository => {
  const auth = useAuthClient();
  return React.useMemo(() => createAuthRepository(auth), [auth]);
};

const createAuthRepository = (auth: Auth) => {
  return {
    async signIn(email: string, password: string) {
      return (await signInWithEmailAndPassword(auth, email, password)).user;
    },

    async signOut() {
      return auth.signOut();
    },

    async getDashboardClaim(user: User) {
      const result = (await user.getIdTokenResult()).claims;
      const isDashboard = result['dashboard'] as boolean | undefined;
      return isDashboard ?? false;
    },
  };
};
