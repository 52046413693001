import React from 'react';
import { Flex } from 'antd';
import { MCustomer } from 'src/models/mCustomer';
import { MCustomerForm } from '../form';
import { useMCustomerItem } from 'src/usecases/mCustomer/reader';
import { TransHistoryTable } from '../../transHistory/table';
import { FullscreenLoading } from 'src/components/function/loading';
import { SurveyResultTable } from '../../surveyResult/table';
import { MCusPCardTable } from '../../mCusPCard/table';

type Props = {
  selectCus: MCustomer | null;
};

export const MCustomerDetail: React.FC<Props> = ({ selectCus }) => {
  const { data, isLoading } = useMCustomerItem({
    mCustomerId: selectCus?.id,
  });

  return (
    <FullscreenLoading isLoading={isLoading}>
      {!!!data?.mCustomer ? (
        <></>
      ) : (
        <Flex vertical gap="middle" style={{ width: '100%' }}>
          <MCustomerForm mCustomerId={data!.mCustomer!.id!} />
          <div style={{ height: '20px' }} />
          <TransHistoryTable mCustomer={data!.mCustomer} />
          <div style={{ height: '20px' }} />
          <SurveyResultTable mCustomer={data!.mCustomer} />
          <div style={{ height: '20px' }} />
          <MCusPCardTable mCustomer={data!.mCustomer} />
          <div style={{ height: '20px' }} />
        </Flex>
      )}
    </FullscreenLoading>
  );
};
