import { DocumentData } from 'firebase/firestore/lite';
import { MCustomerFromJson } from 'smartdishpro-scripts/ts_common';
import {
  convertFirstAttribute,
  convertSecondAttribute,
} from 'src/components/function/customMerchant';
import { LoginAccountState } from 'src/globalStates/loginAccount';
import { MCustomer, toMCustomerId } from 'src/models/mCustomer';
import { escapeSpecialChar } from 'src/util/string';
import { toUpdateFsDoc } from '../common/firestore';

export const toMCustomerFromDoc = (
  data: DocumentData,
  id: string
): MCustomer => {
  return { ...MCustomerFromJson(data, id), id: toMCustomerId(id), key: id };
};

export const toDownloadDataFormat = (
  mCustomers: MCustomer[],
  account: LoginAccountState
): string[][] => {
  const _title = [
    '顧客ID',
    'LINE_ID',
    'LINE_名前',
    'LINE_プロフィール写真',
    'ニックネーム',
    '備考',
    convertFirstAttribute(account.merchantId!),
    convertSecondAttribute(account.merchantId!),
    '来店店舗',
  ];
  const _data: string[][] = [
    _title,
    ...mCustomers.map((_v) => [
      _v.id ?? '',
      _v.lineId ?? '',
      escapeSpecialChar(_v.lineName ?? ''),
      _v.linePictureUrl ?? '',
      escapeSpecialChar(_v.nickname ?? ''),
      escapeSpecialChar(_v.remarks ?? ''),
      _v.firstAttribute ?? '',
      _v.secondAttribute ?? '',
      account.stores
        .filter((v) => _v.storeIds.includes(v.storeId))
        .map((v) => v.storeName)
        .join(','),
    ]),
  ];
  return _data;
};

export const toUpdateDocFromMCustomer = (mCustomer: MCustomer) => {
  return toUpdateFsDoc({
    nickname: mCustomer.nickname,
    remarks: mCustomer.remarks,
    firstAttribute: mCustomer.firstAttribute,
    secondAttribute: mCustomer.secondAttribute,
  });
};
