import { Card, Typography } from 'antd';
import React from 'react';
import { AccountTable } from 'src/components/model/account/table';
import { useLoginAccount } from 'src/globalStates/loginAccount';

export const Account: React.FC = () => {
  const account = useLoginAccount();

  return (
    <>
      {account.master ? (
        <AccountTable />
      ) : (
        <Card
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <Typography.Text style={{ color: 'gray' }}>
            ログイン中のアカウントは権限がないためこの機能をご使用できません
          </Typography.Text>
        </Card>
      )}
    </>
  );
};
