import React from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Breadcrumb, Layout } from 'antd';
import { pathToHeaderCrubms } from 'src/root/path.function';
import { Content } from 'antd/es/layout/layout';

type Props = {
  children: React.ReactNode;
  extraHeaderNames?: { title: string }[];
};

export const AppHeader: React.FC<Props> = ({ children, extraHeaderNames }) => {
  const [breadCrumbs, setBreadCrumbs] = React.useState<{ title: string }[]>([]);
  const location = useLocation();

  React.useEffect(() => {
    setBreadCrumbs([
      ...pathToHeaderCrubms(location.pathname.slice(1)),
      ...(extraHeaderNames ?? []),
    ]);
    return () => {};
  }, [location.pathname, extraHeaderNames]);

  return (
    <>
      <Layout.Header
        style={{
          padding: 0,
          backgroundColor: 'white',
        }}
      >
        <Flex align="center" style={{ height: '100%' }}>
          <Breadcrumb
            style={{
              margin: '15px',
            }}
            items={breadCrumbs}
          />
        </Flex>
      </Layout.Header>
      <Content style={{ padding: '2%' }}>{children}</Content>
    </>
  );
};
