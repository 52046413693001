import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Drawer, Flex, Table } from 'antd';
import * as icon from '@ant-design/icons';
import RoutePaths from 'src/root/path';
import { useLoginAccount } from 'src/globalStates/loginAccount';
import { columns } from './table.type';
import { MCustomerDetail } from '../detail';
import { MCustomer, MCustomerId } from 'src/models/mCustomer';

type Props = {
  mCustomer: MCustomer;
};

export const MCustomerTable: React.FC<Props> = ({ mCustomer }) => {
  const { merchantId, stores } = useLoginAccount();
  const [selectCus, setSelectCus] = React.useState<MCustomer | null>(null);

  const navigate = useNavigate();

  const onClick2 = React.useCallback(
    (id: MCustomerId) => {
      navigate('/' + RoutePaths.directMessage.path + `?id=${id}`, {
        replace: true,
      });
    },
    [navigate]
  );

  const showDrawer = React.useCallback((mCustomer: MCustomer) => {
    setSelectCus(mCustomer);
  }, []);

  const onClose = React.useCallback(() => {
    setSelectCus(null);
  }, []);

  return (
    <>
      <Table
        onRow={(v, _v) => {
          return {
            onClick: () => showDrawer(v),
          };
        }}
        columns={columns(merchantId!, stores, onClick2)}
        dataSource={[mCustomer]}
        size="small"
        sticky
        scroll={{ x: 1000, y: 1000 }}
        rowKey={(record) => record.id ?? 'id'}
      />
      {selectCus ? (
        <Drawer
          width={640}
          placement="right"
          closable={false}
          onClose={onClose}
          open={selectCus !== null}
        >
          <Flex vertical>
            <Button icon={<icon.CloseOutlined />} onClick={onClose}></Button>
            <MCustomerDetail selectCus={selectCus} />
          </Flex>
        </Drawer>
      ) : (
        <></>
      )}
    </>
  );
};
