import React from 'react';
import { useSWRConfig } from 'swr';
import {
  SurveyResultGetListByMCusIdQuery,
  SurveyResultGetListBySurveyIdQuery,
} from './reader';

// Cache Key Generator
export const surveyResultCacheKeyGenerator = {
  generateItemKey: (
    query: SurveyResultGetListBySurveyIdQuery | SurveyResultGetListByMCusIdQuery
  ) => {
    return ['SURVEYRESULT', 'ITEM', query] as const;
  },
};

// Cache Mutator
export const useSurveyResultCacheMutator = () => {
  const { mutate } = useSWRConfig();

  return React.useMemo(
    () => ({
      mutateItem: (query: SurveyResultGetListBySurveyIdQuery) =>
        mutate(surveyResultCacheKeyGenerator.generateItemKey(query)),
    }),
    [mutate]
  );
};
