import { MerchantId, toMerchantId } from 'src/models/merchant';

const _hikariFoodMerchantId = toMerchantId('SpbFkQUUY9V6UU729Ivk');

export const isHikariFood = (merchantId: MerchantId) => {
  return merchantId === _hikariFoodMerchantId;
};

export const convertFirstAttribute = (merchantId: MerchantId) => {
  if (merchantId === _hikariFoodMerchantId) {
    return '大黒属性';
  } else {
    return '属性１';
  }
};

export const convertSecondAttribute = (merchantId: MerchantId) => {
  if (merchantId === _hikariFoodMerchantId) {
    return '魚椿属性';
  } else {
    return '属性２';
  }
};
