import React from 'react';
import { MCusPCardId } from 'src/models/mCusPCard';
import { useSWRConfig } from 'swr';
import {
  MCusPcardGetListByMCusIdQuery,
  MCusPcardGetListByPCardIdQuery,
} from './reader';

// Cache Key Generator
export const mCusPCardCacheKeyGenerator = {
  generateItemKey: (id: MCusPCardId) => {
    return ['MCUSPCARD', 'ITEM', id] as const;
  },
  generateListKey: ({
    query,
  }: {
    query?: MCusPcardGetListByPCardIdQuery | MCusPcardGetListByMCusIdQuery;
    includeAllQuery?: boolean;
  }) => {
    return ['MCUSPCARD', 'LIST', query] as const;
  },
};

// Cache Mutator
export const useMCusPCardCacheMutator = () => {
  const { mutate } = useSWRConfig();

  return React.useMemo(
    () => ({
      mutateItem: (id: MCusPCardId) =>
        mutate(mCusPCardCacheKeyGenerator.generateItemKey(id)),
      mutateList: (
        query: MCusPcardGetListByPCardIdQuery | MCusPcardGetListByMCusIdQuery
      ) =>
        mutate(
          mCusPCardCacheKeyGenerator.generateListKey({
            query,
          })
        ),
    }),
    [mutate]
  );
};
