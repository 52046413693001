import React from 'react';
import { useSWRConfig } from 'swr';

// Cache Key Generator
export const storeCacheKeyGenerator = {
  generateListKey: ({
    query = {},
    includeAllQuery,
  }: {
    query?: {};
    includeAllQuery?: boolean;
  }) => {
    return ['STORE', 'LIST', ...(includeAllQuery ? [] : [query])] as const;
  },
};

// Cache Mutator
export const useStoreCacheMutator = () => {
  const { mutate } = useSWRConfig();

  return React.useMemo(
    () => ({
      mutateList: () =>
        mutate(
          storeCacheKeyGenerator.generateListKey({ includeAllQuery: true })
        ),
    }),
    [mutate]
  );
};
