import React from 'react';
import { AppHeader } from 'src/components/ui';
import { PointCard } from './pointCard';

export const PointCardListPage: React.FC = () => {
  return (
    <AppHeader>
      <PointCard />
    </AppHeader>
  );
};
