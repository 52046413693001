import React from 'react';
import { Table } from 'antd';
import { columns } from './table.type';
import { useAccountList } from 'src/usecases/account/reader';

export const AccountTable: React.FC = () => {
  const values = useAccountList();

  return <Table columns={columns} dataSource={values.data?.accounts ?? []} />;
};
