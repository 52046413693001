import React from 'react';
import { MCustomer } from 'src/components/page/mCustomer/list/mCustomer';
import { AppHeader } from 'src/components/ui';

export const MCustomerPage: React.FC = () => {
  return (
    <AppHeader>
      <MCustomer />
    </AppHeader>
  );
};
