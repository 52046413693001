import React from 'react';
import {
  getDocs,
  collection,
  Firestore,
  query,
  QueryConstraint,
  where,
  limit,
  addDoc,
  getDoc,
  doc,
  orderBy,
  updateDoc,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { BroadCast, getFieldName, toBroadCastId } from 'src/models/broadCast';
import { Collection } from 'src/models/common';
import { toBroadCastFromDoc } from 'src/repositories/braodCast/converter';
import { BroadCastRepository } from 'src/repositories/braodCast/interface';
import { LoginAccountState } from 'src/globalStates/loginAccount';
import { validData } from 'src/util/validate';
import { toCreateFsDoc, toUpdateFsDoc } from '../common/firestore';
import {
  BroadCastGetItemQuery,
  BroadCastGetListQuery,
} from 'src/usecases/broadCast/reader';

export const useBroadCastRepository = (): BroadCastRepository => {
  const db = useFirestoreClient();

  return React.useMemo(() => createBroadCastRepository(db), [db]);
};

const createBroadCastRepository = (db: Firestore) => {
  return {
    async getList({ account }: BroadCastGetListQuery) {
      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.BroadCasts
        }`
      );

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        orderBy('createdAt', 'desc'),
      ];

      const snapshot = await getDocs(query(collRef, ...queries, limit(200)));

      return snapshot.docs
        .map((doc) => toBroadCastFromDoc(doc.data(), doc.id))
        .filter((_broadCast) => validData(_broadCast.storeIds, account));
    },

    async getDoc({ account, broadCastId }: BroadCastGetItemQuery) {
      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.BroadCasts
        }/${broadCastId.toString()}`
      );

      const snapshot = await getDoc(docRef);
      if (snapshot.data() === undefined) {
        throw new Error('エラーが発生しました');
      }

      const _value = toBroadCastFromDoc(snapshot.data()!, snapshot.id);

      const _valid = validData(_value.storeIds, account);
      if (!_valid) {
        throw new Error('エラーが発生しました');
      }

      return _value;
    },

    async createDoc(broadCast: BroadCast, account: LoginAccountState) {
      if (!validData(broadCast.storeIds, account)) {
        throw new Error(
          '送信するアカウントに権限のある店舗が含まれている必要があります'
        );
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.BroadCasts
        }`
      );

      delete broadCast.statusInJp;

      const _result = await addDoc(collRef, toCreateFsDoc(broadCast));

      return this.getDoc({ broadCastId: toBroadCastId(_result.id), account });
    },

    async update(broadCast: BroadCast, account: LoginAccountState) {
      if (!validData(broadCast.storeIds, account)) {
        throw new Error(
          '送信するアカウントに権限のある店舗が含まれている必要があります'
        );
      }

      const _id = broadCast.id;

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.BroadCasts
        }/${_id}`
      );

      delete broadCast.statusInJp;

      await updateDoc(docRef, toUpdateFsDoc(broadCast));

      return this.getDoc({ broadCastId: toBroadCastId(_id!), account });
    },
  };
};
