import useSWR from 'swr';
import { messageRoomCacheKeyGenerator } from './cache';
import { MessageRoom, MessageRoomId } from 'src/models/messageRoom';
import { useMessageRoomRepository } from 'src/repositories/messageRoom/repository';
import { MCustomer } from 'src/models/mCustomer';
import { message } from 'antd';
import { useLoginAccount } from 'src/globalStates/loginAccount';

export type MessageRoomListResponse = { rooms: MessageRoom[] };

export type MessageRoomListQuery = {
  mCustomerId: string | null;
};

export const useMessageRoomList = (query: MessageRoomListQuery) => {
  const repository = useMessageRoomRepository();
  const account = useLoginAccount();

  return useSWR<MessageRoomListResponse>(
    messageRoomCacheKeyGenerator.generateListKey({
      query,
      ignore: !account.merchantId,
    }),
    async () => {
      const _getValue = query.mCustomerId
        ? await repository.getDocFromMCusId(query)
        : await repository.getList();

      return {
        rooms: _getValue,
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};

export type MessageRoomGetItemQuery = {
  mRoomId: MessageRoomId | null;
};

export type MessageRoomResponse = { room: MessageRoom | null };

export const useMessageRoom = (query: MessageRoomGetItemQuery) => {
  const repository = useMessageRoomRepository();
  const account = useLoginAccount();

  return useSWR<MessageRoomResponse>(
    messageRoomCacheKeyGenerator.generateItemKey({
      query,
      ignore: !query.mRoomId || !account.merchantId,
    }),
    async () => {
      return {
        room: await repository.getDoc(query),
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};

export type MessageRoomGetItemResponse = {
  mRoom: MessageRoom | null;
  mCustomer: MCustomer | null;
};
