import { message } from 'antd';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { PointCard, PointCardFormType } from 'src/models/pointCard';
import { PointCardRepository } from 'src/repositories/pointCard/interface';
import { usePointCardRepository } from 'src/repositories/pointCard/repository';
import { StorageRepository } from 'src/repositories/storage/interface';
import { useStorageRepository } from 'src/repositories/storage/repository';
import { usePointCardCacheMutator } from './cache';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';

export const usePointCardForm = () => {
  const repository = usePointCardRepository();
  const storageRepo = useStorageRepository();
  const navigator = useNavigate();
  const mutator = usePointCardCacheMutator();
  const account = useLoginAccount();

  return React.useMemo(
    () =>
      createPointCardFormConstructor({
        repository,
        storageRepo,
        navigator,
        mutator,
        account,
      }),
    [repository, storageRepo, navigator, mutator, account]
  );
};

const createPointCardFormConstructor = ({
  repository,
  storageRepo,
  navigator,
  mutator,
  account,
}: {
  repository: PointCardRepository;
  storageRepo: StorageRepository;
  navigator: NavigateFunction;
  mutator: ReturnType<typeof usePointCardCacheMutator>;
  account: LoginAccountState;
}) => ({
  async submit(value: PointCardFormType, image?: File) {
    try {
      let _imageUrl = value.pointCard?.imageUrl ?? null;
      if (image) {
        _imageUrl = await storageRepo.upload({
          file: image!,
          fileName: image!.name,
          collPath: 'PointCards',
        });
      }

      const _expireAt = value.expireAt.toDate();
      _expireAt.setHours(23, 59, 59, 0);

      if (!!!value.pointCard) {
        const _pointCard: PointCard = {
          caution: value.caution ?? null,
          createdAt: new Date(),
          deletedAt: null,
          documentStatus: 'active',
          availableStoreIds: value.availableStoreIds,
          expireAt: _expireAt,
          imageUrl: _imageUrl,
          title: value.title,
          updatedAt: new Date(),
        };
        await repository.createDoc(_pointCard, account);

        mutator.mutateList({ account });

        message.success('新規作成しました');
      } else {
        const _pointCard: PointCard = {
          ...value.pointCard,
          caution: value.caution ?? null,
          availableStoreIds: value.availableStoreIds,
          expireAt: _expireAt,
          imageUrl: _imageUrl,
          title: value.title,
          updatedAt: new Date(),
        };
        await repository.updateDoc(_pointCard, account);

        mutator.mutateItem({ pId: value.pointCard!.id!, account });

        message.success('更新しました');
      }

      navigator('../');
    } catch (error) {
      message.error('予期せぬエラーが発生しました。再度お試しください。');
    }
    return;
  },
});
