import useSWR from 'swr';
import { pointCardCacheKeyGenerator } from './cache';
import {
  PointCard,
  PointCardFormType,
  PointCardId,
  toFormTypeFromPointCard,
} from 'src/models/pointCard';
import { usePointCardRepository } from 'src/repositories/pointCard/repository';
import { message } from 'antd';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';

export type PointCardGetListQuery = { account: LoginAccountState };

type PointCardGetListResponse = { pointCards: PointCard[] };

export const usePointCardList = () => {
  const repository = usePointCardRepository();
  const account = useLoginAccount();

  return useSWR<PointCardGetListResponse>(
    pointCardCacheKeyGenerator.generateListKey({ ignore: !account.merchantId }),
    async () => {
      const _getValue = await repository.getList({ account });
      return {
        pointCards: _getValue,
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};

export type PointCardGetItemQuery = {
  pId: PointCardId;
  account: LoginAccountState;
};

export type PointCardGetItemResponse = { pointCard: PointCardFormType };

export const usePointCardFormItem = ({ pId }: { pId: PointCardId }) => {
  const repository = usePointCardRepository();
  const account = useLoginAccount();

  return useSWR<PointCardGetItemResponse>(
    pointCardCacheKeyGenerator.generateItemKey({
      query: { pId, account },
      ignore: !account.merchantId,
    }),
    async () => {
      const _getValue = await repository.getDoc({ pId, account });
      return {
        pointCard: toFormTypeFromPointCard(_getValue),
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};
