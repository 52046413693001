import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { AppSider } from 'src/components/ui';
import packageJson from '../../../../package.json';

export const Top: React.FC = () => {
  return (
    <Layout style={{ minHeight: '100vh', overflow: 'auto' }}>
      <AppSider />
      <Layout>
        <Outlet />
        <Layout.Footer
          style={{ textAlign: 'center', fontSize: '10px', color: 'darkgray' }}
        >
          v{packageJson.version} CARCH, Inc ©2023
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};
