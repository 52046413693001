import { DocumentData } from 'firebase/firestore/lite';
import { PointCardFromJson } from 'smartdishpro-scripts/ts_common';
import { PointCard, toPointCardId } from 'src/models/pointCard';

export const toPointCardFromDoc = (
  data: DocumentData,
  id: string
): PointCard => {
  const _pointCard = PointCardFromJson(data);
  return {
    ..._pointCard,
    id: toPointCardId(id),
    key: id,
  };
};
