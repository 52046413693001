import { BroadCast, BroadCastFormType, BroadCastId } from './type';
import moment from 'moment';

export function getFieldName<K extends keyof BroadCast>(key: K): K {
  return key;
}

export function toBroadCastId(id: string): BroadCastId {
  return id as BroadCastId;
}

export function toFormTypeFromBroadCast(
  broadCast: BroadCast
): BroadCastFormType {
  return {
    broadCast: broadCast,
    altText: broadCast.message.altText,
    tempLabel: broadCast.message.tempLabel,
    tempUri: broadCast.message.tempUri,
    text: broadCast.message.text,
    type: broadCast.message.type,
    sendAt: broadCast.sendAt === null ? null : moment(broadCast.sendAt),
    sendMCustomerLineIds: broadCast.sendMCustomerLineIds,
    sendAll:
      broadCast.sendMCustomerLineIds === null ||
      broadCast.sendMCustomerLineIds.length === 0,
    storeIds: broadCast.storeIds,
    title: broadCast.title,
  };
}
