import React from 'react';
import { AppHeader } from 'src/components/ui';
import { Account } from './account';

export const AccountListPage: React.FC = () => {
  return (
    <AppHeader>
      <Account />
    </AppHeader>
  );
};
