import React, { useState } from 'react';
import { Button, Flex, Form, Input } from 'antd';
import { useLoginUsecase } from 'src/usecases/account/usecase';

type FieldType = {
  email?: string;
  password?: string;
};

export const Login: React.FC = () => {
  const { login } = useLoginUsecase();

  const [loading, isLoading] = useState(false);

  const onFinish = React.useCallback(
    async (value: FieldType) => {
      isLoading(true);
      await login(value.email!, value.password!);
      isLoading(false);
    },
    [login]
  );

  return (
    <Flex
      justify="center"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Form
        name="basic"
        layout="vertical"
        style={{
          paddingTop: '20vh',
        }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        labelWrap
        disabled={loading}
      >
        <Form.Item<FieldType>
          label="メールアドレス"
          name="email"
          rules={[{ required: true, message: 'メールアドレスを入力してね' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="パスワード"
          name="password"
          rules={[{ required: true, message: 'パスワードを入力してね' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            ログイン
          </Button>
        </Form.Item>
      </Form>
    </Flex>
  );
};
