import React from 'react';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/ja_JP';
import { AppTheme } from 'src/components/styles/theme';

type Props = {
  children?: React.ReactNode;
};

const Theme: React.FC<Props> = ({ children }) => {
  return (
    <ConfigProvider theme={AppTheme} locale={locale}>
      {children}
    </ConfigProvider>
  );
};

export default Theme;
