import React from 'react';
import {
  getDocs,
  collection,
  Firestore,
  query,
  QueryConstraint,
  where,
  limit,
  addDoc,
  getDoc,
  doc,
  updateDoc,
  orderBy,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { Collection } from 'src/models/common';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';
import { toSurveyFromDoc } from './converter';
import { SurveyRepository } from './interface';
import { Survey, toSurveyId, getFieldName } from 'src/models/survey';
import { validData } from 'src/util/validate';
import { toCreateFsDoc, toUpdateFsDoc } from '../common/firestore';
import {
  SurveyGetItemQuery,
  SurveyGetListQuery,
} from 'src/usecases/survey/reader';

export const useSurveyRepository = (): SurveyRepository => {
  const db = useFirestoreClient();
  const account = useLoginAccount();

  return React.useMemo(
    () => createSurveyRepository(db, account),
    [db, account]
  );
};

const createSurveyRepository = (db: Firestore, account: LoginAccountState) => {
  return {
    async getList({ account }: SurveyGetListQuery) {
      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.Surveys
        }`
      );

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        orderBy(getFieldName('createdAt'), 'desc'),
      ];

      const snapshot = await getDocs(query(collRef, ...queries, limit(200)));

      return snapshot.docs
        .map((doc) => toSurveyFromDoc(doc.data(), doc.id))
        .filter((_survey) => validData(_survey.availableStoreIds, account));
    },

    async getDoc({ sId: surveyId, account }: SurveyGetItemQuery) {
      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.Surveys
        }/${surveyId.toString()}`
      );

      const snapshot = await getDoc(docRef);
      if (snapshot.data() === undefined) {
        throw new Error('エラーが発生しました');
      }

      const _value = toSurveyFromDoc(snapshot.data()!, snapshot.id);

      const _validate = validData(_value.availableStoreIds, account);
      if (!_validate) {
        throw new Error('権限がありません');
      }

      return _value;
    },

    async createDoc(survey: Survey) {
      if (!validData(survey.availableStoreIds, account)) {
        throw new Error(
          'アクセス可能の店舗に権限のある店舗が含まれている必要があります'
        );
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.Surveys
        }`
      );

      const _result = await addDoc(collRef, toCreateFsDoc(survey));

      return this.getDoc({ sId: toSurveyId(_result.id), account });
    },

    async updateDoc(survey: Survey) {
      const _surveyId = survey.id;

      if (!!!_surveyId) {
        throw new Error('エラーが発生しました。再度お試しください。');
      }

      if (!validData(survey.availableStoreIds, account)) {
        throw new Error(
          'アクセス可能の店舗に権限のある店舗が含まれている必要があります'
        );
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.Surveys
        }/${_surveyId}`
      );

      await updateDoc(docRef, toUpdateFsDoc(survey));

      return this.getDoc({ sId: _surveyId, account });
    },
  };
};
