import React, { useCallback, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Card,
  Space,
  Select,
  Flex,
  Typography,
  Radio,
} from 'antd';
import { PointCardFormType } from 'src/models/pointCard';
import { SurveyFormType } from 'src/models/survey';
import { useSurveyForm } from 'src/usecases/survey/form';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { useLoginAccount } from 'src/globalStates/loginAccount';
import { useStoreList } from 'src/usecases/store/reader';
import TextArea from 'antd/es/input/TextArea';

interface Prop {
  init?: SurveyFormType;
}

export const SurveyForm: React.FC<Prop> = ({ init }) => {
  const _formState = useSurveyForm();

  const loginAccount = useLoginAccount();

  const [form] = Form.useForm<SurveyFormType>();

  const nameValue = Form.useWatch('questions', form);

  const [loading, isLoading] = useState(false);

  const confirm = useCallback(
    async (value: SurveyFormType) => {
      isLoading(true);
      await _formState.submit({ ...value, survey: init?.survey });
      isLoading(false);
    },
    [_formState, init]
  );

  const { data } = useStoreList({
    storeIds: loginAccount.storeIds,
  });

  return (
    <Card style={{ paddingTop: '3%' }}>
      <Form
        labelWrap
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14, offset: 1 }}
        layout="horizontal"
        style={{ maxWidth: 1000, margin: 'auto' }}
        form={form}
        onFinish={confirm}
        initialValues={init}
        disabled={loading}
      >
        <Form.Item<PointCardFormType>
          label="タイトル"
          name="title"
          rules={[{ required: true, message: 'タイトルを入力してください' }]}
          style={{ padding: '1%' }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="storeIds"
          label="利用可能店舗"
          rules={[
            {
              message: '送信する公式アカウントを選択してください',
              type: 'array',
            },
          ]}
          style={{ padding: '1%' }}
        >
          <Select
            mode="multiple"
            placeholder="送信する公式アカウントを選択してください"
          >
            {loginAccount.stores?.map((_store) => {
              return (
                <Select.Option value={_store.storeId} key={_store.storeId}>
                  {_store.storeName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="質問" name="questions" required>
          <Form.List name="questions">
            {(subFields, subOpt) => (
              <div
                style={{
                  display: 'flex-start',
                  flexDirection: 'column',
                  rowGap: 16,
                }}
              >
                {subFields.map((subField, index) => (
                  <Card
                    key={subField.key + index}
                    style={{
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      marginBottom: '50px',
                    }}
                  >
                    <Flex
                      style={{
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}
                    >
                      <Form.Item
                        name={[subField.name, 'required']}
                        rules={[
                          {
                            required: true,
                            message: '回答が必須か任意か選択してください',
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={true}>必須</Radio>
                          <Radio value={false}>任意</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <DeleteOutlined
                        onClick={() => subOpt.remove(subField.name)}
                      />
                    </Flex>
                    <Space style={{ alignItems: 'flex-start' }}>
                      <Form.Item
                        noStyle
                        name={[subField.name, 'question']}
                        rules={[
                          {
                            required: true,
                            message: '質問を入力してください',
                          },
                        ]}
                      >
                        <TextArea placeholder="質問" autoSize />
                      </Form.Item>
                      <Form.Item
                        noStyle
                        name={[subField.name, 'type']}
                        rules={[
                          {
                            required: true,
                            message: '回答形式を選択してください',
                          },
                        ]}
                      >
                        <Select placeholder="回答形式">
                          <Select.Option value="text">テキスト</Select.Option>
                          <Select.Option value="number">数字</Select.Option>
                          <Select.Option value="radio">単数選択</Select.Option>
                          <Select.Option value="checkbox">
                            複数選択
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      {nameValue &&
                      nameValue[subField.name] &&
                      (nameValue[subField.name].type === 'radio' ||
                        nameValue[subField.name].type === 'checkbox') ? (
                        <Form.List
                          name={[subField.name, 'selectionsForm']}
                          rules={[
                            {
                              validator: async (_, selections) => {
                                if (selections.length > 20) {
                                  return Promise.reject(
                                    new Error('選択肢は20個までです')
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          {(fields, { add, remove }, { errors }) => (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: 16,
                              }}
                            >
                              {fields.map((field) => (
                                <Space key={field.key}>
                                  <Form.Item
                                    name={[field.name, 'text']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: '選択肢を入力してください',
                                      },
                                    ]}
                                    noStyle
                                  >
                                    <Input placeholder="選択肢" />
                                  </Form.Item>
                                  {fields.length > 1 ? (
                                    <CloseOutlined
                                      onClick={() => remove(field.name)}
                                    />
                                  ) : null}
                                </Space>
                              ))}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                >
                                  + 選択肢を追加
                                </Button>
                              </Form.Item>
                              <Form.ErrorList errors={errors} />
                            </div>
                          )}
                        </Form.List>
                      ) : (
                        <></>
                      )}
                    </Space>
                  </Card>
                ))}
                <Button type="dashed" onClick={() => subOpt.add()} block>
                  + 質問を追加
                </Button>
              </div>
            )}
          </Form.List>
        </Form.Item>
        {init ? (
          <Form.Item label="URL" style={{ padding: '1%' }}>
            {init.survey?.availableStoreIds?.map((_id) => {
              const _findStore = data?.stores.find(
                (_store) => _store.id === _id
              );

              if (!!!_findStore) {
                return <div key={_id}></div>;
              }
              const _url = _findStore.liffUrl + '/survey/' + init!.survey?.id;
              return (
                <Flex
                  key={_id}
                  gap={5}
                  vertical
                  style={{ paddingBottom: '20px' }}
                >
                  <Typography.Text style={{ paddingRight: '10px' }}>
                    {_findStore.storeName}
                  </Typography.Text>
                  <Typography.Paragraph copyable>{_url}</Typography.Paragraph>
                </Flex>
              );
            })}
          </Form.Item>
        ) : (
          <></>
        )}
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            確定
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
