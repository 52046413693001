import ReactDOM from 'react-dom/client';
import App from 'src/App';
import reportWebVitals from 'src/reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);

reportWebVitals();
