import React from 'react';
import {
  Firestore,
  doc,
  getDoc,
  getDocs,
  query,
  collection,
  QueryConstraint,
  where,
  limit,
  startAfter,
  orderBy,
  updateDoc,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { MCustomerRepository } from 'src/repositories/mCustomer/interface';
import { Collection } from 'src/models/common';
import { toMCustomerFromDoc, toUpdateDocFromMCustomer } from './converter';
import {
  MCustomerGetItemQuery,
  MCustomerUpdateItemQuery,
} from 'src/usecases/mCustomer/reader';
import { getFieldName, MCustomer } from 'src/models/mCustomer';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';
import { validData } from 'src/util/validate';

export const useMCustomerRepository = (): MCustomerRepository => {
  const db = useFirestoreClient();
  const account = useLoginAccount();
  return React.useMemo(
    () => createMCustomerRepository(db, account),
    [db, account]
  );
};

const createMCustomerRepository = (
  db: Firestore,
  account: LoginAccountState
) => {
  return {
    async getDoc({ mCustomerId }: MCustomerGetItemQuery) {
      if (account.merchantId === undefined || mCustomerId === undefined) {
        throw new Error('エラーが発生しました');
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MCustomers
        }/${mCustomerId.toString()}`
      );

      const snapshot = await getDoc(docRef);

      if (!snapshot.exists) {
        return null;
      }

      const _mCustomer = toMCustomerFromDoc(snapshot.data()!, snapshot.id);
      if (
        !_mCustomer.storeIds.some((storeId) =>
          account.storeIds?.includes(storeId)
        )
      ) {
        return null;
      }

      if (!validData(_mCustomer.storeIds, account)) {
        throw new Error('権限がありません');
      }

      return _mCustomer;
    },

    async getAllDocs() {
      if (account.merchantId === undefined) {
        throw new Error('エラーが発生しました');
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId?.toString()}/${
          Collection.MCustomers
        }`
      );

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        orderBy(getFieldName('createdAt'), 'desc'),
      ];

      if (!account.master) {
        queries.push(
          where(
            getFieldName('storeIds'),
            'array-contains-any',
            account.storeIds ?? []
          )
        );
      }

      const data: MCustomer[] = [];

      for (let index = 0; index < 10000; index++) {
        queries.push(
          startAfter(data[data.length - 1]?.createdAt ?? new Date())
        );
        const _snap = (
          await getDocs(query(collRef, ...queries, limit(200)))
        ).docs.map((_doc) => toMCustomerFromDoc(_doc.data()!, _doc.id));

        data.push(..._snap);
        if (_snap.length < 200) {
          break;
        }
      }

      return data.filter((_v) => validData(_v.storeIds, account));
    },

    async updateDoc({
      mCustomer,
    }: MCustomerUpdateItemQuery): Promise<MCustomer> {
      if (account.merchantId === undefined) {
        throw new Error('エラーが発生しました');
      }

      if (!validData(mCustomer.storeIds, account)) {
        throw new Error('権限がありません');
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MCustomers
        }/${mCustomer.id}`
      );

      await updateDoc(docRef, toUpdateDocFromMCustomer(mCustomer));

      const _updatedMCus = await this.getDoc({ mCustomerId: mCustomer.id! });
      if (_updatedMCus === null) {
        throw new Error('エラーが発生しました');
      }

      return _updatedMCus;
    },
  };
};
