import moment from 'moment';
import { PointCard, PointCardFormType, PointCardId } from './type';

export function getFieldName<K extends keyof PointCard>(key: K): K {
  return key;
}

export function toPointCardId(id: string): PointCardId {
  return id as PointCardId;
}

export function toFormTypeFromPointCard(
  pointCard: PointCard
): PointCardFormType {
  return {
    pointCard: pointCard,
    ...pointCard,
    expireAt: moment(pointCard.expireAt),
  };
}
