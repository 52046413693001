import { message } from 'antd';
import React from 'react';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';
import { PointCardId } from 'src/models/pointCard';
import {
  toDownloadHistoriesFormat,
  toDownloadMCusPCardsFormat,
} from 'src/repositories/mCusPCard/converter';
import { MCusPCardRepository } from 'src/repositories/mCusPCard/interface';
import { useMCusPCardRepository } from 'src/repositories/mCusPCard/repository';
import { MCustomerRepository } from 'src/repositories/mCustomer/interface';
import { useMCustomerRepository } from 'src/repositories/mCustomer/repository';
import { download } from 'src/util/download';

export const usePointCardUsecase = () => {
  const mCusPCardRepo = useMCusPCardRepository();
  const mCusRepo = useMCustomerRepository();
  const account = useLoginAccount();

  return React.useMemo(
    () =>
      createPointCardUsecase({
        mCusPCardRepo,
        mCusRepo,
        account,
      }),
    [mCusPCardRepo, account]
  );
};

const createPointCardUsecase = ({
  mCusPCardRepo,
  mCusRepo,
  account,
}: {
  mCusPCardRepo: MCusPCardRepository;
  mCusRepo: MCustomerRepository;
  account: LoginAccountState;
}) => ({
  async downloadData(
    format: 'csv' | 'xlsx',
    pCardId: PointCardId,
    type: 'customer' | 'history'
  ) {
    try {
      const _results = await mCusPCardRepo.getListByPCardId({
        pCardId: pCardId,
      });
      let _data: string[][];
      let _fileName = '';
      if (type === 'customer') {
        const _mCusResults = await mCusRepo.getAllDocs(account);
        const _value = _results.map((element, i) => {
          return {
            mCusPCard: element, lineId: _mCusResults.find((v, i) => v.id === element.mCustomerId)?.lineId ?? undefined
          }
        })

        _data = toDownloadMCusPCardsFormat(_value, account.stores);
        _fileName = '顧客_ポイントカードデータ';
      } else {
        _data = toDownloadHistoriesFormat(_results, account.stores);
        _fileName = 'ポイント付与履歴データ';
      }
      download(_data, _fileName, format);
    } catch (error) {
      message.error(
        '予期せぬエラーが発生しました。ページを更新して再度お試しください。'
      );
    }
  },
});
