import useSWR from 'swr';
import { storeCacheKeyGenerator } from './cache';
import { Store } from 'src/models/store';
import { useStoreRepository } from 'src/repositories/store/repository';
import { message } from 'antd';

type StoreGetListResponse = { stores: Store[] };

export type StoreGetListQuery = { storeIds: string[] | undefined };

export const useStoreList = (query: StoreGetListQuery) => {
  const repository = useStoreRepository();

  return useSWR<StoreGetListResponse>(
    storeCacheKeyGenerator.generateListKey({ query }),
    async () => {
      if (query.storeIds === undefined) {
        return { stores: [] };
      }

      const _getValue = await repository.getList(query.storeIds);
      return {
        stores: _getValue,
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};
