import React from 'react';
import { AppHeader } from 'src/components/ui';
import { SurveyEditForm } from './survey.edit';
import { useLocation } from 'react-router-dom';
import { FullscreenLoading } from 'src/components/function/loading';
import { getSurveyId } from 'src/root/path.function';
import { useSurveyFormItem } from 'src/usecases/survey/reader';

export const SurveyEditPage: React.FC = () => {
  const location = useLocation();

  const _state = useSurveyFormItem({
    sId: getSurveyId(location.pathname),
  });

  return (
    <FullscreenLoading isLoading={_state.data?.survey === undefined}>
      <AppHeader
        extraHeaderNames={[{ title: _state.data?.survey?.title ?? '' }]}
      >
        {_state.data?.survey === undefined ? (
          <></>
        ) : (
          <SurveyEditForm formValue={_state.data.survey} />
        )}
      </AppHeader>
    </FullscreenLoading>
  );
};
