import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import * as icon from '@ant-design/icons';
import { AppColors } from 'src/components/styles/color';
import { MCustomer, getFieldName, MCustomerId } from 'src/models/mCustomer';
import { dateToYMdhm } from 'src/util';
import {
  convertFirstAttribute,
  convertSecondAttribute,
} from 'src/components/function/customMerchant';
import { MerchantId } from 'src/models/merchant';
import { StoreOfAccount } from 'src/models/account';

export const columns = (
  merchantId: MerchantId,
  stores: StoreOfAccount[],
  onClick2: (id: MCustomerId) => void
): ColumnsType<MCustomer> => {
  return [
    {
      title: '名前',
      dataIndex: 'lineName',
      key: getFieldName('lineName'),
      ellipsis: {
        showTitle: false,
      },
      render: (v: string) => (
        <div style={{ color: AppColors.lightBlue }}>{v}</div>
      ),
    },
    {
      title: 'ニックネーム',
      dataIndex: 'nickname',
      key: getFieldName('nickname'),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: '登録日時',
      dataIndex: 'createdAt',
      key: getFieldName('createdAt'),
      ellipsis: {
        showTitle: false,
      },
      render: (v: Date) => <div>{dateToYMdhm(v)}</div>,
    },
    {
      title: convertFirstAttribute(merchantId),
      dataIndex: 'firstAttribute',
      key: getFieldName('firstAttribute'),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: convertSecondAttribute(merchantId),
      dataIndex: 'secondAttribute',
      key: getFieldName('secondAttribute'),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: '店舗',
      dataIndex: 'storeIds',
      key: getFieldName('storeIds'),
      ellipsis: {
        showTitle: false,
      },
      render: (v: string[]) => {
        const _storeNames = v
          .map((_v) => stores.find((s) => s.storeId === _v)?.storeName ?? '')
          .join(',');
        return (
          <Tooltip placement="topLeft" title={_storeNames}>
            {_storeNames}
          </Tooltip>
        );
      },
    },
    {
      title: 'DMへ移動',
      dataIndex: 'id',
      key: getFieldName('id'),
      render: (id: MCustomerId) => (
        <Tooltip title="この顧客とのメッセージ画面に遷移します">
          <icon.MailOutlined
            style={{ color: AppColors.lightBlue }}
            onClick={() => onClick2(id)}
          />
        </Tooltip>
      ),
    },
  ];
};
