import React from 'react';
import {
  Avatar,
  Button,
  Card,
  Divider,
  Flex,
  Radio,
  Space,
  Typography,
} from 'antd';
import * as icon from '@ant-design/icons';
import { useLoginAccount } from 'src/globalStates/loginAccount';
import { useLoginUsecase } from 'src/usecases/account/usecase';
import { AppColors } from 'src/components/styles/color';

export const MyAccount: React.FC = () => {
  const account = useLoginAccount();
  const { logout } = useLoginUsecase();

  return (
    <Card
      style={{
        maxWidth: '500px',
        minHeight: '90%',
      }}
    >
      <Flex gap="middle" align="center" vertical>
        <Avatar size={100} icon={<icon.UserOutlined />} />
        <Flex
          vertical
          align="center"
          style={{ marginTop: '20px', width: '90%' }}
        >
          <Typography.Title
            level={5}
            style={{ alignSelf: 'center', color: AppColors.darkGray }}
          >
            メールアドレス
          </Typography.Title>
          <Typography.Text>{account.email}</Typography.Text>
        </Flex>
        <Divider />
        <Flex vertical style={{ width: '90%' }}>
          <Typography.Title
            level={5}
            style={{ alignSelf: 'flex-start', color: AppColors.darkGray }}
          >
            アカウントタイプ
          </Typography.Title>
          <Radio.Group value={account.master}>
            <Space direction="vertical">
              <Radio value disabled>
                マスター
              </Radio>
              <Radio value={false} disabled>
                一般
              </Radio>
            </Space>
          </Radio.Group>
        </Flex>
        <div style={{ height: '10px' }} />
        <Flex vertical style={{ width: '90%' }}>
          <Typography.Title
            level={5}
            style={{ alignSelf: 'flex-start', color: AppColors.darkGray }}
          >
            アクセス権限のある店舗
          </Typography.Title>
          <Typography.Text
            style={{ alignSelf: 'flex-start', whiteSpace: 'pre-line' }}
          >
            {account.stores
              .map((_store) => _store.storeName)
              .join('\n')
              .toString()}
          </Typography.Text>
        </Flex>
        <Button
          type="text"
          style={{ justifySelf: 'flex-end', marginTop: '40px' }}
          danger
          onClick={logout}
        >
          ログアウト
        </Button>
      </Flex>
    </Card>
  );
};
