import { serverTimestamp } from 'firebase/firestore/lite';

export const toCreateFsDoc = <T>(doc: T & { key?: string; id?: string }) => {
  delete doc.key;
  delete doc.id;
  return {
    ...doc,
    createdAt: serverTimestamp(),
    documentStatus: 'active',
    updatedAt: serverTimestamp(),
  };
};

export const toUpdateFsDoc = <T>(doc: T & { key?: string; id?: string }) => {
  delete doc.key;
  delete doc.id;
  return {
    ...doc,
    updatedAt: serverTimestamp(),
  };
};
