import { message } from 'antd';
import React from 'react';
import { MessageRoom } from 'src/models/messageRoom';
import {
  MessageGetListQuery,
  MessageRepository,
} from 'src/repositories/message/interface';
import { useMessageRepository } from 'src/repositories/message/repository';
import { StorageRepository } from 'src/repositories/storage/interface';
import { useStorageRepository } from 'src/repositories/storage/repository';

export const useMessageUsecase = () => {
  const repository = useMessageRepository();
  const storageRepo = useStorageRepository();

  return React.useMemo(
    () =>
      createMessageUsecase({
        repository,
        storageRepo,
      }),
    [repository, storageRepo]
  );
};

export type SendMessageQuery = {
  selectRoom: MessageRoom | null;
  text: string | undefined;
  file: File | undefined;
  fileName: string | undefined;
};

const createMessageUsecase = ({
  repository,
  storageRepo,
}: {
  repository: MessageRepository;
  storageRepo: StorageRepository;
}) => ({
  async sendMessage(query: SendMessageQuery) {
    if (query.selectRoom === null) {
      message.error('エラーが発生しました。更新してもう一度お試しください。');
      return;
    }

    try {
      if (query.file && query.fileName) {
        const _url = await storageRepo.upload({
          file: query.file!,
          fileName: query.fileName!,
          collPath: 'Messages',
        });
        await repository.createDoc({
          content: _url,
          contentType: 'image',
          destinationUserId: query.selectRoom.mCusLineId,
          messageRoomId: query.selectRoom.id!,
        });
      }

      if (query.text) {
        await repository.createDoc({
          content: query.text,
          contentType: 'text',
          destinationUserId: query.selectRoom.mCusLineId,
          messageRoomId: query.selectRoom.id!,
        });
      }
    } catch (error) {
      message.error('エラーが発生しました。更新してもう一度お試しください。');
    }
  },

  async getMessages(query: MessageGetListQuery) {
    try {
      return (await repository.getList(query)).reverse();
    } catch (error) {
      message.error('エラーが発生しました。更新してもう一度お試しください。');
      return [];
    }
  },
});
