import React from 'react';
import { AppHeader } from 'src/components/ui';
import { BroadCastCreateForm } from './broadCast.create';

export const BroadCastCreatePage: React.FC = () => {
  return (
    <AppHeader>
      <BroadCastCreateForm />
    </AppHeader>
  );
};
