import React from 'react';
import { AppHeader } from 'src/components/ui';
import { PointCardEditForm } from './pointCard.edit';
import { useLocation } from 'react-router-dom';
import { FullscreenLoading } from 'src/components/function/loading';
import { usePointCardFormItem } from 'src/usecases/pointCard/reader';
import { getPointCardId } from 'src/root/path.function';

export const PointCardEditPage: React.FC = () => {
  const location = useLocation();
  const _state = usePointCardFormItem({
    pId: getPointCardId(location.pathname),
  });

  return (
    <FullscreenLoading isLoading={_state.data?.pointCard === undefined}>
      <AppHeader
        extraHeaderNames={[{ title: _state.data?.pointCard.title ?? '' }]}
      >
        {_state.data?.pointCard === undefined ? (
          <></>
        ) : (
          <PointCardEditForm formValue={_state.data.pointCard} />
        )}
      </AppHeader>
    </FullscreenLoading>
  );
};
