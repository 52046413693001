import { utils, write } from 'xlsx';
import { dateToYMdhmS } from '.';

export const download = (
  data: string[][],
  fileName: string,
  format: 'csv' | 'xlsx'
) => {
  const _data = data.map((record) => record.join(',')).join('\r\n');
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const _fileName = `${fileName}_${dateToYMdhmS(new Date())}`;

  const link = document.createElement('a');
  if (format === 'csv') {
    const blob = new Blob([bom, _data], { type: 'text/csv' });
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    link.download = `${_fileName}.csv`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    const sheet = utils.aoa_to_sheet(data);
    const book = utils.book_new();
    const sheetName = 'Sheet1';
    utils.book_append_sheet(book, sheet, sheetName);
    const buffer: Uint8Array = write(book, { type: 'array' });
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${_fileName}.xlsx`);
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  }
};
