import React from 'react';
import { PointCardForm } from 'src/components/model/pointCard/form';
import { PointCardFormType } from 'src/models/pointCard';

type Props = {
  formValue: PointCardFormType;
};

export const PointCardEditForm: React.FC<Props> = ({ formValue }) => {
  return <PointCardForm init={formValue} />;
};
