import { DocumentData, DocumentReference } from 'firebase/firestore/lite';
import { AccountFromJson } from 'smartdishpro-scripts/ts_common';
import { Account, toAccountId } from 'src/models/account';
import { toMerchantId } from 'src/models/merchant';

export const toAccountFromDoc = (
  data: DocumentData,
  ref: DocumentReference
): Account => {
  const _account = AccountFromJson(data);
  return {
    ..._account,
    id: toAccountId(ref.id),
    key: ref.id,
    merchantId: toMerchantId(ref.path.split('/')[1]),
    storeIds: _account.stores.map((store) => store.storeId),
  };
};
