import React from 'react';
import { useMCusPCardCacheMutator } from './cache';
import { MessageInstance } from 'antd/es/message/interface';
import { MCusPCard } from 'src/models/mCusPCard';
import { useMCusPCardRepository } from 'src/repositories/mCusPCard/repository';
import { MCusPCardRepository } from 'src/repositories/mCusPCard/interface';
import { toMCustomerId } from 'src/models/mCustomer';

export const useMCusPCardUsecase = () => {
  const repository = useMCusPCardRepository();
  const mutator = useMCusPCardCacheMutator();

  return React.useMemo(
    () => createMCustomerUsecase({ repository, mutator }),
    [repository, mutator]
  );
};

export interface MCusPCardUsecase {
  updateMCusPCard(value: MCusPCard, message: MessageInstance): Promise<void>;
}

const createMCustomerUsecase = ({
  repository,
  mutator,
}: {
  repository: MCusPCardRepository;
  mutator: ReturnType<typeof useMCusPCardCacheMutator>;
}) => ({
  async updateMCusPCard(value: MCusPCard, message: MessageInstance) {
    try {
      await repository.update({
        point: value.point,
        mCusPCard: value,
        history: {
          point: value.point,
          pointedAt: new Date(),
          storeId: null,
        },
      });
      message.success('変更しました');

      mutator.mutateList({
        mCustomerId: toMCustomerId(value.mCustomerId),
        limit: 200,
      });
    } catch (error) {
      message.error('予期せぬエラーが発生しました。再度お試しください。');
    }
  },
});
