import React from 'react';
import { Spin } from 'antd';

type Props = {
  children: React.ReactNode;
  isLoading: boolean;
};

export const FullscreenLoading: React.FC<Props> = ({ children, isLoading }) => {
  return (
    <>
      <>{children}</>
      <Spin spinning={isLoading} fullscreen />
    </>
  );
};
