import React from 'react';
import { AppHeader } from 'src/components/ui';
import { MyAccount } from './myaccount';

export const MyAccountPage: React.FC = () => {
  return (
    <AppHeader>
      <MyAccount />
    </AppHeader>
  );
};
