import React from 'react';
import { useSWRConfig } from 'swr';
import { TransHistoryGetListQuery } from './reader';

// Cache Key Generator
export const transHistoryCacheKeyGenerator = {
  generateItemKey: ({ mCustomerId }: TransHistoryGetListQuery) => {
    return ['TRANSHISTORY', 'ITEM', mCustomerId] as const;
  },
};

// Cache Mutator
export const useTransHistoryCacheMutator = () => {
  const { mutate } = useSWRConfig();

  return React.useMemo(
    () => ({
      mutateItem: (query: TransHistoryGetListQuery) =>
        mutate(transHistoryCacheKeyGenerator.generateItemKey(query)),
    }),
    [mutate]
  );
};
