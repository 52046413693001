import React from 'react';
import { AppHeader } from 'src/components/ui';
import { PointCardCreateForm } from './pointCard.create';

export const PointCardCreatePage: React.FC = () => {
  return (
    <AppHeader>
      <PointCardCreateForm />
    </AppHeader>
  );
};
