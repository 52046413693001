import React from 'react';
import { useStorageClient } from 'src/firebase';
import { Collection } from 'src/models/common';
import {
  useLoginAccount,
  LoginAccountState,
} from 'src/globalStates/loginAccount';
import { FirebaseStorage } from 'firebase/storage';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { StorageRepository } from './interface';
import { v4 as uuidv4 } from 'uuid';

export const useStorageRepository = (): StorageRepository => {
  const storage = useStorageClient();
  const account = useLoginAccount();

  return React.useMemo(
    () => createStorageRepository(storage, account),
    [storage, account]
  );
};

export type UpadloadFileQuery = {
  file: Blob;
  fileName: string;
  collPath: 'Messages' | 'BroadCasts' | 'PointCards';
};

const createStorageRepository = (
  storage: FirebaseStorage,
  account: LoginAccountState
) => {
  return {
    async upload({ file, fileName, collPath }: UpadloadFileQuery) {
      const storageRef = ref(
        storage,
        `${Collection.Merchants}/${
          account.merchantId
        }/${collPath}/${uuidv4()}/${fileName}`
      );
      const _result = await uploadBytes(storageRef, file);
      return await getDownloadURL(_result.ref);
    },
  };
};
