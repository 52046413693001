import React from 'react';
import { BroadCastForm } from 'src/components/model/broadCast/form';
import { BroadCastFormType } from 'src/models/broadCast';

type Props = {
  formValue: BroadCastFormType;
};

export const BroadCastEditForm: React.FC<Props> = ({ formValue }) => {
  return <BroadCastForm init={formValue} />;
};
