import React from 'react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PointCard, getFieldName } from 'src/models/pointCard';
import { usePointCardList } from 'src/usecases/pointCard/reader';
import { usePointCardUsecase } from 'src/usecases/pointCard/usecase';
import { Flex } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DownloadButton } from 'src/components/ui';
import { dateToYMd } from 'src/util';

export const PointCardTable: React.FC = () => {
  const navigate = useNavigate();

  const onClickEdit = React.useCallback(
    (v: PointCard) => {
      return {
        onClick: () => {
          if (!v?.id) return;
          navigate(v.id!);
          return;
        },
      };
    },
    [navigate]
  );

  const values = usePointCardList();

  const _state = usePointCardUsecase();

  const columns: ColumnsType<PointCard> = [
    {
      title: 'タイトル',
      dataIndex: getFieldName('title'),
      key: getFieldName('title'),
    },
    {
      title: '有効期限',
      dataIndex: getFieldName('expireAt'),
      key: getFieldName('expireAt'),
      render(_, record, __) {
        if (record.expireAt === null) {
          return '-';
        }
        return dateToYMd(record.expireAt);
      },
    },
    {
      title: '作成日',
      dataIndex: getFieldName('createdAt'),
      key: getFieldName('createdAt'),
      render(_, record, __) {
        if (record.createdAt === null) {
          return '-';
        }
        return dateToYMd(record.createdAt);
      },
    },
    {
      title: 'ダウンロードデータ',
      dataIndex: 'export',
      key: 'export',
      render(_, record, __) {
        return (
          <a onClick={(event) => event.stopPropagation()} href={_}>
            <Flex gap={10}>
              <DownloadButton
                name="顧客データ"
                onClick={(f) => _state.downloadData(f, record.id!, 'customer')}
              />
              <DownloadButton
                name="ポイント付与履歴データ"
                onClick={(f) => _state.downloadData(f, record.id!, 'history')}
              />
            </Flex>
          </a>
        );
      },
    },
  ];

  return (
    <Table<PointCard>
      columns={columns}
      dataSource={values.data?.pointCards ?? []}
      onRow={onClickEdit}
    />
  );
};
