import React from 'react';
import { AppHeader } from 'src/components/ui';
import { BroadCastEditForm } from './broadCast.edit';
import { useLocation } from 'react-router-dom';
import { FullscreenLoading } from 'src/components/function/loading';
import { getBroadCastId } from 'src/root/path.function';
import { useBroadCastFormItem } from 'src/usecases/broadCast/reader';

export const BroadCastEditPage: React.FC = () => {
  const location = useLocation();
  const _state = useBroadCastFormItem({
    bId: getBroadCastId(location.pathname),
  });

  return (
    <FullscreenLoading isLoading={_state.data?.broadCast === undefined}>
      <AppHeader
        extraHeaderNames={[{ title: _state.data?.broadCast.title ?? '' }]}
      >
        {_state.data?.broadCast === undefined ? (
          <></>
        ) : (
          <BroadCastEditForm formValue={_state.data.broadCast} />
        )}
      </AppHeader>
    </FullscreenLoading>
  );
};
