import React from 'react';
import {
  Firestore,
  getDocs,
  QueryConstraint,
  where,
  limit,
  query,
  collection,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { Collection } from 'src/models/common';
import { getFieldName } from 'src/models/account';
import { toStoreFromDoc } from './converter';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';
import { StoreRepository } from './interface';

export const useStoreRepository = (): StoreRepository => {
  const db = useFirestoreClient();
  const account = useLoginAccount();

  return React.useMemo(() => createStoreRepository(db, account), [db, account]);
};

const createStoreRepository = (db: Firestore, account: LoginAccountState) => {
  return {
    async getList(storeIds: string[]) {
      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.Stores
        }`
      );

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
      ];

      const snapshot = await getDocs(query(collRef, ...queries, limit(100)));

      return snapshot.docs
        .filter((_doc) => storeIds.includes(_doc.id))
        .map((doc) => toStoreFromDoc(doc.data(), doc.ref));
    },
  };
};
