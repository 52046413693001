import { DocumentData, DocumentReference } from 'firebase/firestore/lite';
import { StoreFromJson } from 'smartdishpro-scripts/ts_common';
import { toStoreId } from 'src/models/store';
import { Store } from 'src/models/store';

export const toStoreFromDoc = (
  data: DocumentData,
  ref: DocumentReference
): Store => {
  const _store = StoreFromJson(data, ref.id);
  return {
    ..._store,
    id: toStoreId(ref.id),
  };
};
