import React from 'react';
import {
  getDocs,
  collection,
  Firestore,
  query,
  QueryConstraint,
  where,
  limit,
  orderBy,
  startAfter,
  doc,
  updateDoc,
  getDoc,
  arrayUnion,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { Collection } from 'src/models/common';
import {
  MCusPCardRepository,
  MCusPcardGetQuery,
  MCusPcardUpdateQuery,
} from './interface';
import { getFieldName, MCusPCard } from 'src/models/mCusPCard';
import { toMCusPCardFromDoc } from './converter';
import {
  MCusPcardGetListByMCusIdQuery,
  MCusPcardGetListByPCardIdQuery,
} from 'src/usecases/mCusPCard/reader';
import { validData } from 'src/util/validate';
import { toUpdateFsDoc } from '../common/firestore';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';

export const useMCusPCardRepository = (): MCusPCardRepository => {
  const db = useFirestoreClient();

  const account = useLoginAccount();

  return React.useMemo(
    () => createMCusPCardRepository(db, account),
    [db, account]
  );
};

const createMCusPCardRepository = (
  db: Firestore,
  account: LoginAccountState
) => {
  async function _getList(queries: QueryConstraint[], limitNum: number) {
    const collRef = collection(
      db,
      `${Collection.Merchants}/${account.merchantId!.toString()}/${
        Collection.MCusPCards
      }`
    );
    const snapshot = await getDocs(query(collRef, ...queries, limit(limitNum)));
    return snapshot.docs.map((doc) => toMCusPCardFromDoc(doc.data(), doc.id));
  }

  return {
    async getListByPCardId({ pCardId }: MCusPcardGetListByPCardIdQuery) {
      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        where(getFieldName('pointCardId'), '==', pCardId.toString()),
        orderBy(getFieldName('createdAt'), 'desc'),
      ];

      const data: MCusPCard[] = [];

      for (let index = 0; index < 10000; index++) {
        queries.push(
          startAfter(data[data.length - 1]?.createdAt ?? new Date())
        );
        const _snap = await _getList(queries, 200);

        data.push(..._snap);
        if (_snap.length < 200) {
          break;
        }
      }
      return data.filter((_v) => validData(_v.availableStoreIds, account));
    },

    async getListByMCusId({
      mCustomerId,
      limit,
    }: MCusPcardGetListByMCusIdQuery) {
      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        where(getFieldName('mCustomerId'), '==', mCustomerId),
        orderBy(getFieldName('createdAt'), 'desc'),
      ];

      return (await _getList(queries, limit)).filter((_v) =>
        validData(_v.availableStoreIds, account)
      );
    },

    async update({ point, mCusPCard, history }: MCusPcardUpdateQuery) {
      if (
        account.merchantId === undefined ||
        !validData(mCusPCard.availableStoreIds, account)
      ) {
        throw new Error('権限がありません');
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MCusPCards
        }/${mCusPCard.id}`
      );

      await updateDoc(
        docRef,
        toUpdateFsDoc({
          point: point,
          histories: arrayUnion(history),
        })
      );
    },

    async get({ id }: MCusPcardGetQuery) {
      if (account.merchantId === undefined) {
        throw new Error('エラーが発生しました');
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MCusPCards
        }/${id.toString()}`
      );

      const _snap = await getDoc(docRef);

      if (!!!_snap.exists) {
        return null;
      }

      const _mCusPCard = toMCusPCardFromDoc(_snap.data()!, _snap.id);
      if (!validData(_mCusPCard.availableStoreIds, account)) {
        return null;
      }

      return _mCusPCard;
    },
  };
};
