import React from 'react';
import { useSWRConfig } from 'swr';
import {
  PointCardGetItemQuery,
  PointCardGetItemResponse,
  PointCardGetListQuery,
} from './reader';

// Cache Key Generator
export const pointCardCacheKeyGenerator = {
  generateListKey: ({
    query,
    ignore = false,
  }: {
    query?: PointCardGetListQuery;
    ignore?: boolean;
  }) => {
    if (ignore) {
      return;
    }

    return ['POINTCARD', 'LIST', [query]] as const;
  },

  generateItemKey: ({
    query,
    ignore,
  }: {
    query: PointCardGetItemQuery;
    ignore?: boolean;
  }) => {
    if (ignore) {
      return;
    }

    return ['POINTCARD', 'ITEM', query] as const;
  },
};

// Cache Mutator
export const usePointCardCacheMutator = () => {
  const { mutate } = useSWRConfig();

  return React.useMemo(
    () => ({
      mutateList: (query: PointCardGetListQuery) =>
        mutate(pointCardCacheKeyGenerator.generateListKey({ query })),
      mutateItem: (
        query: PointCardGetItemQuery,
        newData?: PointCardGetItemResponse,
        revalidate?: boolean
      ) =>
        mutate(
          pointCardCacheKeyGenerator.generateItemKey({
            query,
          }),
          newData,
          revalidate
        ),
    }),
    [mutate]
  );
};
