import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout, Flex, Image, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { notEmpty } from 'src/util/array';
import { TopPageRoutes } from 'src/components/function/top.page.route';

export const AppSider: React.FC = () => {
  const [collapsed, setCollapsed] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key);
  };

  return (
    <Layout.Sider
      style={{
        overflow: 'auto',
        left: 0,
        top: 0,
        bottom: 0,
      }}
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
      <Flex justify="center">
        <Image
          src="/assets/images/logo.png"
          width="18vh"
          height="7vh"
          style={{
            paddingBottom: '10px',
            paddingTop: '10px',
            objectFit: 'contain',
          }}
          preview={false}
        />
      </Flex>
      <Menu
        defaultSelectedKeys={location.pathname.split('/').map((_v) => _v + '/')}
        defaultOpenKeys={TopPageRoutes.map((v) => v?.key?.toString()).filter(
          notEmpty
        )}
        mode="inline"
        items={TopPageRoutes}
        onClick={onClick}
      />
    </Layout.Sider>
  );
};
