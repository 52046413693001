import React, { useCallback, useEffect } from 'react';
import {
  Avatar,
  Button,
  Flex,
  Image,
  List,
  message,
  Tooltip,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import * as icon from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { FullscreenLoading } from 'src/components/function/loading';
import { useMessageUsecase } from 'src/usecases/message/usecase';
import { AppColors } from 'src/components/styles/color';
import { dateTohm } from 'src/util';
import { RcFile } from 'antd/es/upload';
import { Message } from 'src/models/message';
import { DownOutlined } from '@ant-design/icons';
import { MessageRoomGetItemResponse } from 'src/usecases/messageRoom/reader';
import { useMessageRoomUsecase } from 'src/usecases/messageRoom/usecase';

interface Prop {
  selectRoom: MessageRoomGetItemResponse | undefined;
}

const MessageQueryLimit = 100;

export const Chat: React.FC<Prop> = ({ selectRoom }) => {
  const { sendMessage, getMessages } = useMessageUsecase();

  const { updateRoomToRead } = useMessageRoomUsecase();

  const [messages, setMessages] = React.useState<Message[]>([]);

  const [loading, setLoading] = React.useState(false);

  const [noMoreData, setNoMoreData] = React.useState(false);

  useEffect(() => {
    setNoMoreData(false);
    if (!!!selectRoom?.mRoom?.id) {
      return;
    }

    setLoading(true);
    getMessages({
      mRoomId: selectRoom?.mRoom?.id,
      lastCreatedAt: undefined,
      queryLimit: MessageQueryLimit,
    }).then((res) => {
      updateRoomToRead({ mRoomId: selectRoom!.mRoom!.id! });

      setMessages(res);
      setLoading(false);
      if (res.length < MessageQueryLimit) {
        setNoMoreData(true);
      }
    });
    return () => {
      updateRoomToRead({ mRoomId: selectRoom!.mRoom!.id! });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRoom?.mRoom?.id]);

  const readMore = useCallback(
    async (lastCreatedAt: Date) => {
      setLoading(true);
      const _messages = await getMessages({
        mRoomId: selectRoom?.mRoom?.id,
        lastCreatedAt: lastCreatedAt,
        queryLimit: MessageQueryLimit,
      });

      const _value = [..._messages, ...messages];
      setMessages(_value);
      setLoading(false);
      if (messages.length > 0 && _messages.length < MessageQueryLimit) {
        setNoMoreData(true);
      }
    },
    [getMessages, messages, selectRoom?.mRoom?.id]
  );

  const [input, setInput] = React.useState<string | undefined>();
  const [inputImage, setInputImage] = React.useState<RcFile | undefined>();

  const clearInput = useCallback(() => {
    setInput(undefined);
    setInputImage(undefined);
  }, []);

  const send = useCallback(async () => {
    const _input = input?.trim();
    if (loading || ((_input?.length ?? 0) === 0 && !inputImage)) {
      return;
    }

    setLoading(true);
    await sendMessage({
      selectRoom: selectRoom?.mRoom ?? null,
      text: _input,
      file: inputImage,
      fileName: inputImage?.name,
    });
    clearInput();
    setLoading(false);
    const res = await getMessages({
      mRoomId: selectRoom?.mRoom?.id,
      lastCreatedAt: undefined,
      queryLimit: MessageQueryLimit,
    });
    setMessages(res);
  }, [
    clearInput,
    getMessages,
    input,
    inputImage,
    loading,
    selectRoom?.mRoom,
    sendMessage,
  ]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!loading) {
        setInput(e.target.value);
      }
    },
    [loading]
  );

  const props: UploadProps = {
    accept: 'image/*',
    showUploadList: inputImage !== undefined,
    beforeUpload: (file) => {
      if (loading) {
        return false;
      }
      const isImage =
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg';
      if (!isImage) {
        message.error(`png, jpeg, jpg形式のみアップロード可能です`);
        return false;
      }

      setInputImage(file);
      return false;
    },
    onRemove: () => setInputImage(undefined),
    maxCount: 1,
  };

  return (
    <FullscreenLoading isLoading={loading}>
      <Flex
        vertical
        style={{
          width: '43%',
          height: '75vh',
          background: 'white',
          borderRadius: 3,
        }}
      >
        {!!!selectRoom?.mRoom ? (
          <Typography.Text style={{ alignSelf: 'center', paddingTop: '30%' }}>
            チャットが選択されていません
          </Typography.Text>
        ) : (
          <>
            <div
              style={{
                width: '100%',
                overflow: 'auto',
                flexGrow: 1,
                overflowY: 'auto',
                padding: '3%',
              }}
            >
              {messages.length > 0 ? (
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                  }}
                >
                  <Button
                    disabled={noMoreData}
                    loading={loading}
                    onClick={() => readMore(messages[0].createdAt)}
                  >
                    さらに読み込み
                  </Button>
                </div>
              ) : (
                <></>
              )}
              <List
                split={false}
                dataSource={messages}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    {item.messageType === 'storeToUser' ? (
                      <Flex
                        style={{ width: '100%' }}
                        justify="flex-end"
                        align="end"
                      >
                        <Typography.Text
                          style={{
                            width: '20%',
                            fontSize: 10,
                            color: 'gray',
                            padding: '0 5px 0 5px',
                            textAlign: 'end',
                          }}
                        >
                          {dateTohm(item.createdAt)}
                        </Typography.Text>
                        {item.contentType === 'image' ? (
                          <Image
                            src={item.content}
                            style={{ width: '300px' }}
                          />
                        ) : (
                          <Typography.Text
                            style={{
                              maxWidth: '80%',
                              backgroundColor: AppColors.lightGray,
                              padding: '5px 10px 5px 10px',
                              borderRadius: '5px',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            {item.content}
                          </Typography.Text>
                        )}
                      </Flex>
                    ) : (
                      <Flex
                        style={{ width: '100%' }}
                        justify="flex-start"
                        align="end"
                      >
                        <Avatar src={selectRoom?.mCustomer?.linePictureUrl} />
                        <div style={{ width: '5px' }} />
                        {item.contentType === 'image' ? (
                          <Image
                            src={item.content}
                            style={{ width: '300px' }}
                          />
                        ) : (
                          <Typography.Text
                            style={{
                              maxWidth: '80%',
                              backgroundColor: AppColors.lightGray,
                              padding: '5px 10px 5px 10px',
                              borderRadius: '5px',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            {item.content}
                          </Typography.Text>
                        )}
                        <Typography.Text
                          style={{
                            width: '20%',
                            fontSize: 10,
                            color: 'gray',
                            padding: '0 5px 0 5px',
                            textAlign: 'start',
                          }}
                        >
                          {dateTohm(item.createdAt)}
                        </Typography.Text>
                      </Flex>
                    )}
                  </List.Item>
                )}
              />
              <div id="scroll"> </div>
            </div>
            <div
              style={{
                width: '100%',
                position: 'static',
              }}
            >
              <Button
                icon={<DownOutlined />}
                style={{
                  color: AppColors.darkGray,
                  margin: '10px',
                  backgroundColor: 'transparent',
                  position: 'absolute',
                  top: '80%',
                }}
                onClick={() => {
                  const element = document.getElementById('scroll');
                  if (element) {
                    element.scrollIntoView({ behavior: 'auto' });
                  }
                }}
              />
              <Flex
                justify="space-evenly"
                align="flex-end"
                style={{ padding: '2%' }}
                gap={3}
              >
                {inputImage !== undefined ? (
                  <></>
                ) : (
                  <TextArea
                    value={input}
                    disabled={loading || inputImage !== undefined}
                    placeholder="メッセージを入力してください"
                    autoSize={{ minRows: 3, maxRows: 7 }}
                    maxLength={500}
                    style={{ borderRadius: 3, width: '100%' }}
                    onChange={onChange}
                  />
                )}
                <Flex vertical gap={5} align="center">
                  <Tooltip title="png, jpeg, jpg形式のみアップロード可能です">
                    <Flex
                      vertical
                      align="center"
                      style={{
                        width: '100%',
                        maxWidth: '500px',
                        overflow: 'scroll',
                      }}
                    >
                      <Upload {...props}>
                        {inputImage !== undefined ? (
                          <></>
                        ) : (
                          <Button
                            disabled={loading}
                            type="text"
                            icon={<icon.UploadOutlined />}
                          />
                        )}
                      </Upload>
                    </Flex>
                  </Tooltip>
                  <Button loading={loading} onClick={send}>
                    送信
                  </Button>
                </Flex>
              </Flex>
            </div>
          </>
        )}
      </Flex>
    </FullscreenLoading>
  );
};
