import React from 'react';
import { Message } from 'src/components/page/directMessage/directMessage';
import { AppHeader } from 'src/components/ui';

export const DirectMessagePage: React.FC = () => {
  return (
    <AppHeader>
      <Message />
    </AppHeader>
  );
};
