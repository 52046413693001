import { DocumentData } from 'firebase/firestore/lite';
import { SurveyFromJson } from 'smartdishpro-scripts/ts_common';
import { Survey, toSurveyId } from 'src/models/survey';

export const toSurveyFromDoc = (data: DocumentData, id: string): Survey => {
  const _survey = SurveyFromJson(data);

  return {
    ..._survey,
    id: toSurveyId(id),
    key: id,
  };
};
