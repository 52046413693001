import { DocumentData } from 'firebase/firestore/lite';
import { BroadCastFromJson } from 'smartdishpro-scripts/ts_common';
import { BroadCast, toBroadCastId } from 'src/models/broadCast';

export const toBroadCastFromDoc = (
  data: DocumentData,
  id: string
): BroadCast => {
  const _broadCast = BroadCastFromJson(data);
  return {
    ..._broadCast,
    id: toBroadCastId(id),
    key: id,
    statusInJp: toStatusInJp(data.status),
  };
};

function toStatusInJp(status: BroadCast['status']) {
  switch (status) {
    case 'draft':
      return '下書き';
    case 'reserved':
      return '予約済み';
    case 'sent':
      return '配信済み';

    default:
      return;
  }
}
