import React from 'react';
import { Button, Card, Flex, Typography } from 'antd';
import * as icon from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { PointCardTable } from 'src/components/model/pointCard/table';

export const PointCard: React.FC = () => {
  const navigate = useNavigate();

  const onClickCreate = React.useCallback(() => navigate('new'), [navigate]);

  return (
    <div style={{ height: '100%' }}>
      <Card style={{ height: '10%' }}>
        <Flex justify="space-between" align="middle">
          <Typography.Title level={4} style={{ alignSelf: 'center' }}>
            ポイントカード
          </Typography.Title>
          <Button
            type="primary"
            icon={<icon.PlusOutlined />}
            onClick={onClickCreate}
          >
            新規作成
          </Button>
        </Flex>
      </Card>
      <div style={{ height: '15px' }} />
      <PointCardTable />
    </div>
  );
};
