import React from 'react';
import type { MenuProps } from 'antd';
import * as icon from '@ant-design/icons';
import RouterPath from 'src/root/path';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const TopPageRoutes: MenuItem[] = [
  getItem('顧客', 'customerGroup', <icon.UserOutlined />, [
    getItem(
      RouterPath.customer.siderName,
      RouterPath.customer.path + RouterPath.list.path,
      <icon.UnorderedListOutlined />
    ),
  ]),
  getItem('LINE連携', 'lineGroup', <icon.MessageOutlined />, [
    getItem(
      RouterPath.directMessage.siderName,
      RouterPath.directMessage.path,
      <icon.MailOutlined />
    ),
    getItem(
      RouterPath.broadCast.siderName,
      RouterPath.broadCast.path,
      <icon.SendOutlined />
    ),
    getItem(
      RouterPath.pointCard.siderName,
      RouterPath.pointCard.path,
      <icon.IdcardOutlined />
    ),
    getItem(
      RouterPath.survey.siderName,
      RouterPath.survey.path,
      <icon.ProfileOutlined />
    ),
  ]),
  getItem('アカウント管理', 'accountGroup', <icon.TeamOutlined />, [
    getItem(
      RouterPath.myaccount.siderName,
      RouterPath.myaccount.path,
      <icon.UserOutlined />
    ),
    getItem(
      RouterPath.account.siderName,
      RouterPath.account.path + RouterPath.list.path,
      <icon.UnorderedListOutlined />
    ),
  ]),
];
