import React from 'react';
import { BroadCast } from 'src/components/page/broadCast/list/broadCast';
import { AppHeader } from 'src/components/ui';

export const BroadCastListPage: React.FC = () => {
  return (
    <AppHeader>
      <BroadCast />
    </AppHeader>
  );
};
