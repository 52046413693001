import { LoginAccountState } from 'src/globalStates/loginAccount';

// Cache Key Generator
export const accountCacheKeyGenerator = {
  generateListKey: ({
    query,
    ignore = false,
  }: {
    query?: { account: LoginAccountState };
    ignore?: boolean;
  }) => {
    if (ignore) {
      return;
    }

    return ['ACCOUNT', 'LIST', [query]] as const;
  },
};
