import { DocumentData } from 'firebase/firestore/lite';
import {
  MCusPCardFromJson,
  StoreOfAccount,
} from 'smartdishpro-scripts/ts_common';
import { MCusPCard, toMCusPCardId } from 'src/models/mCusPCard';
import { dateToYMdhm } from 'src/util';

export const toMCusPCardFromDoc = (
  data: DocumentData,
  id: string
): MCusPCard => {
  const _mCusPCard = MCusPCardFromJson(data);
  return {
    ..._mCusPCard,
    id: toMCusPCardId(id),
    key: id,
  };
};

export const toDownloadMCusPCardsFormat = (
  mCusPCards: { mCusPCard: MCusPCard, lineId: string | undefined }[],
  storeAccounts: StoreOfAccount[]
): string[][] => {
  const _title = [
    '顧客ポイントカードID',
    '顧客ID',
    'LINEID',
    '名前',
    '番号',
    'ポイント',
    'ポイントカードID',
    '発行日時',
    '発行店舗ID',
    '発行店舗名',
  ];
  const _data: string[][] = [
    _title,
    ...mCusPCards.map((v) => {
      const _v = v.mCusPCard
      return [
        _v.id ?? '',
        _v.mCustomerId ?? '',
        v.lineId ?? '',
        _v.name ?? '',
        _v.num?.toString() ?? '',
        _v.point?.toString() ?? '',
        _v.pointCardId ?? '',
        dateToYMdhm(_v.createdAt) ?? '',
        _v.createdStoreId,
        storeAccounts.find((_s) => _s.storeId === _v.createdStoreId)?.storeName ??
        '',
      ]
    }),
  ];
  return _data;
};

export const toDownloadHistoriesFormat = (
  mCusPCards: MCusPCard[],
  storeAccounts: StoreOfAccount[]
): string[][] => {
  const _title = [
    '顧客ポイントカードID',
    '顧客ID',
    '名前',
    '番号',
    'ポイントカードID',
    '保有ポイント',
    '付与日時',
    '店舗ID',
    '店舗名',
  ];
  const _data: string[][] = [
    _title,
    ...mCusPCards
      .map((_v) => {
        return _v.histories.map((_h) => [
          _v.id ?? '',
          _v.mCustomerId ?? '',
          _v.name ?? '',
          _v.num?.toString() ?? '',
          _v.pointCardId ?? '',
          _h.point?.toString() ?? '',
          dateToYMdhm(_h.pointedAt) ?? '',
          _h.storeId ?? '',
          storeAccounts.find((_s) => _s.storeId === _h.storeId)?.storeName ??
          '',
        ]);
      })
      .flat(),
  ];
  return _data;
};
