import { DocumentData } from 'firebase/firestore/lite';
import { TransHistoryFromJson } from 'smartdishpro-scripts/ts_common';
import { toTransHistoryId, TransHistory } from 'src/models/transHistory';

export const toTransHistoryFromDoc = (
  data: DocumentData,
  id: string
): TransHistory => {
  return {
    ...TransHistoryFromJson(data, id),
    id: toTransHistoryId(id),
    key: id,
  };
};
