import React from 'react';
import { Authentication } from 'src/components/function/authentication';
import { Top } from 'src/components/page/top/top';

export const TopPage: React.FC = () => {
  return (
    <Authentication>
      <Top />
    </Authentication>
  );
};
