import type { ThemeConfig } from 'antd';
import { AppColors } from 'src/components/styles/color';

export const AppTheme: ThemeConfig = {
  token: {
    colorPrimary: AppColors.lightBlue,
    borderRadius: 3,
    colorBgContainer: AppColors.white,
  },
  components: {
    Menu: {
      subMenuItemBg: AppColors.white,
    },
  },
};
